import { theme } from "@project/common"
import styled from "styled-components"
export const StyledResponsiveCalendarTabContent = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .calendar_tbl_top {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .responsive__calendar_container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .responsive__calendar__content {
      display: flex;
      gap: 8px;
      border-radius: 12px;
      padding: 8px;
      &--left {
        display: flex;
        flex-direction: column;

        .day_of_m {
          font-size: 16px;
          font-weight: 500;
        }
      }
      &--right {
        width: 100%;
        .inactive__container {
          display: flex;
          align-items: center;
          gap: 8px;
          background-color: #f1f4f5;
          border-radius: 6px;
          padding: 4px 8px;
          .time {
            display: flex;
            flex: 1;
            justify-content: center;
          }
          .dots {
            display: flex;
            align-items: center;
            gap: 8px;
            .dot {
              width: 8px;
              height: 8px;
              border-radius: 50%;
            }
            .green {
              background-color: ${theme.colors.green2};
            }
            .yellow {
              background-color: ${theme.colors.orange2};
            }
          }
        }
      }
    }
    .active__container {
      border: 1px solid ${theme.colors.green2};
      .responsive__calendar__content--left {
        padding: 10px 0px 0px 0px;
      }
    }
  }
`
