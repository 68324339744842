import { Box, Button, theme } from "@project/common"
import { Flex } from "antd"
import Image from "next/image"
import { useRouter } from "next/router"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
const MessageWrapper = styled(Flex)`
  width: 40%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 20px;
  background-color: ${theme.colors.white};
  .txt {
    font-weight: 500px;
    font-size: 16px;
  }
  @media (max-width: 768px) {
    width: 95%;
  }
`
export const PasswordReset = (): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  return (
    <Box
      display={"flex"}
      direction={"column"}
      justify={"center"}
      align={"center"}
      h={"100vh"}
      w={"100%"}
      px={16}
    >
      <Image
        src={"/assets/logo.svg"}
        alt={t("logo")}
        height={100}
        width={100}
      />

      <Box component={"h3"} mb={16} mt={16} fz={"calc(18px)"} fw={700}>
        {t("User management screen")}
      </Box>
      <MessageWrapper vertical gap={18}>
        <div className={"txt"}>
          {t(
            "Password re-registration page to registered e-mail address We have sent you an email with the URL. From the URL in this email Please re-register your password.",
          )}
        </div>
        <Flex justify={"center"}>
          <Button
            btnText={t("To login page")}
            shape={"round"}
            btnBg={theme.colors.green2}
            textColor={theme.colors.white}
            width={"fit-content"}
            onClick={() => router?.replace("/login")}
          />
        </Flex>
      </MessageWrapper>
    </Box>
  )
}
