import { DatePicker, Tag, theme, useUpdateSearchParams } from "@project/common"
import { Flex, Skeleton } from "antd"
import dayjs, { Dayjs } from "dayjs"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import {
  FULL_DATE_FORMAT_HM,
  REQUEST_STATUS,
  YM_FORMAT,
} from "../../../constants"

import {
  APPROVED_STATUS,
  ATTENDANCE_UPDATE_REQUEST,
  PAID_LEAVE_REQUEST,
} from "../../../../adult/constants"
import { useDailyRecordMutation } from "../../../hooks"
import {
  UserDailyRecordRequestWithAttendance as ChangeAttendanceRecReq,
  OneDailyRecordRequest,
} from "../../../types"
import { breakTimeInStringV2, getSumOfAllBreakTimes } from "../../../utils"
import { EditRequestFormModal } from "../TabContents/TabContentModals/EditRequestFormModal"
import { editRequestEditModal } from "../TabContents/TabContentModals/utils/editRequestFormMapper"
import { TableActionBtns } from "./CalenderContentMacroComp"
const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
  .request_tbl_content {
    border: 1px solid ${theme.colors?.border};
    padding: 8px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 14px;
    .request_tbl_label_value {
      border: 1px solid ${theme.colors?.border};
      border-radius: 5px;

      .cell {
        padding: 8px;
        word-break: break-word;
      }
      .request_tbl_label {
        background-color: ${theme.colors.background};
        padding: 8px;
        border-bottom: 1px solid ${theme.colors?.border};
        border-radius: 5px 5px 0 0;
      }
      .request_tbl_sub_content {
        width: 100%;
        display: flex;
        &--left,
        &--right {
          display: flex;
          flex-direction: column;
          flex: 1;
        }
        &--left {
          border-right: 1px solid ${theme.colors?.border};
        }
      }
    }
    .request_tbl_cta {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;
      .cta {
        width: fit-content;
        margin: auto;
        padding-bottom: 2px;
        cursor: pointer;
      }
      .edit {
        border-bottom: 2px solid ${theme.colors?.green2};
        span {
          color: ${theme.colors?.green2};
          margin-left: 4px;
        }
      }
      .cancel {
        border-bottom: 2px solid ${theme.colors?.error};
        span {
          color: ${theme.colors?.error};
          margin-left: 4px;
        }
      }
    }
  }
`
export const ResponsiveCommonTabContent = ({
  dailyRecordsRequest,
  isLoading,
  request_type,
  currentDate,
  record_of,
}: {
  request_type: number
  dailyRecordsRequest: OneDailyRecordRequest
  isLoading: boolean
  currentDate: Dayjs
  record_of: string
}): JSX.Element => {
  const { t } = useTranslation()

  const [openEditModal, setOpenEditModal] = useState(false)
  const [updateParams] = useUpdateSearchParams()
  const [selectedRow, setSelectedRow] = useState<ChangeAttendanceRecReq>(null)
  const today = currentDate
    ? dayjs(currentDate).isValid
      ? dayjs(currentDate)
      : dayjs()
    : dayjs()
  const [dateValue, setDateValue] = useState(today || dayjs())

  const { mutate, isUpdating } = useDailyRecordMutation({
    onSuccess: () => {
      setOpenEditModal(false)
      setSelectedRow(null)
    },
  })

  const handleEditRequest = (rec: ChangeAttendanceRecReq) => {
    setSelectedRow(rec)
    setOpenEditModal(true)
  }

  const handleCancelationConfirmation = () => {}

  const handleRequestAgain = (rec: ChangeAttendanceRecReq) => {
    setSelectedRow({ ...rec, requestTo: "re-request", status: 0 })
    setOpenEditModal(true)
  }
  const dailyRecordsRequestData =
    dailyRecordsRequest?.data?.filter(
      (item) => item.request_type === request_type,
    ) || []

  return (
    <Flex vertical gap={16}>
      <DatePicker
        date={dateValue || dayjs()}
        format={YM_FORMAT}
        picker={"month"}
        onDateChange={(date) => {
          setDateValue(date)
          updateParams(
            { daily_record: record_of, date: date.format("YYYY-MM") },
            "",
          )
        }}
        popupClassName={"date-picker-popup-height-responsive"}
      />
      {isLoading ? (
        <Flex>
          <Skeleton active />
        </Flex>
      ) : (
        <>
          {dailyRecordsRequestData?.map((request) => {
            const value = REQUEST_STATUS?.find(
              (v) => v?.value === request?.status,
            )?.label
            const sumOfAllBreaks = getSumOfAllBreakTimes(
              request.attendance[0],
              currentDate.format("YYYY-MM-DD"),
            )
            const registeredTotalBreakTime = breakTimeInStringV2(sumOfAllBreaks)

            const sumOfUpdatedBreaks = getSumOfAllBreakTimes(
              request,
              currentDate.format("YYYY-MM-DD"),
              true,
            )
            const updatedTotalBreakTime =
              breakTimeInStringV2(sumOfUpdatedBreaks)

            return (
              <Wrapper key={request?.id}>
                <div className={"request_tbl_content"}>
                  <div className={"status"}>
                    <Tag
                      title={t(value)}
                      color={
                        request?.status === APPROVED_STATUS
                          ? "success"
                          : request?.status === 0
                            ? "warning"
                            : "error"
                      }
                      style={{
                        textTransform: "uppercase",
                        width: "100%",
                        padding: "6px",
                      }}
                    />
                  </div>
                  {request_type === PAID_LEAVE_REQUEST && (
                    <div className={"request_tbl_label_value"}>
                      <div className={"request_tbl_label cell"}>
                        {t("Requested on")}
                      </div>
                      <div className={"request_tbl_value cell"}>
                        {dayjs(request?.created_datetime).format(
                          FULL_DATE_FORMAT_HM,
                        )}
                      </div>
                    </div>
                  )}
                  <div className={"request_tbl_label_value"}>
                    <div className={"request_tbl_label cell"}>
                      {t("Requested Date")}
                    </div>
                    <div className={"request_tbl_value cell"}>
                      {dayjs(request?.attendance[0]?.date).format(
                        FULL_DATE_FORMAT_HM,
                      )}
                    </div>
                  </div>
                  {request_type === ATTENDANCE_UPDATE_REQUEST && (
                    <div className={"request_tbl_label_value"}>
                      <div className={"request_tbl_sub_content"}>
                        <div className={"request_tbl_sub_content--left"}>
                          <div className={"request_tbl_label cell sub_cell"}>
                            {t("Registered time")}
                          </div>
                          <div className={"request_tbl_value cell"}>
                            <div>
                              <span>
                                {request?.attendance[0]?.start_time}
                                {" ~ "}
                                {request?.attendance[0]?.end_time}
                              </span>
                              <br />
                              <span>{`( ${registeredTotalBreakTime} )`}</span>
                            </div>
                          </div>
                        </div>
                        <div className={"request_tbl_sub_content--right"}>
                          <div className={"request_tbl_label cell sub_cell"}>
                            {t("Changed time")}
                          </div>
                          <div className={"request_tbl_value cell"}>
                            <div>
                              <span>
                                {request?.new_check_in_time}
                                {" ~ "}
                                {request?.new_check_out_time}
                              </span>
                              <br />
                              <span>{`( ${updatedTotalBreakTime} )`}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className={"request_tbl_label_value"}>
                    <div className={"request_tbl_label cell"}>
                      {t("Reason")}
                    </div>
                    <div className={"request_tbl_value cell"}>
                      {request?.reason || "--"}
                    </div>
                  </div>

                  <TableActionBtns
                    status={request.status}
                    handleRequestAgain={() => handleRequestAgain(request)}
                    handleEditRequest={() => handleEditRequest(request)}
                    handleCancelationConfirmation={
                      handleCancelationConfirmation
                    }
                  />
                </div>
              </Wrapper>
            )
          })}
          {dailyRecordsRequestData?.length === 0 && (
            <div
              className={"no-data"}
              style={{
                height: "10vh",
                textAlign: "center",
              }}
            >
              {t("No data")}
            </div>
          )}
        </>
      )}
      <DatePicker
        date={dateValue || dayjs()}
        format={YM_FORMAT}
        picker={"month"}
        onDateChange={(date) => {
          setDateValue(date)
          updateParams(
            { daily_record: record_of, date: date.format("YYYY-MM") },
            "",
          )
        }}
        popupClassName={"date-picker-popup-height-responsive"}
      />

      <EditRequestFormModal
        requestType={request_type as 1 | 2}
        open={openEditModal}
        onCancel={() => {
          setOpenEditModal(false)
          setSelectedRow(null)
        }}
        title={t("Request Form")}
        footer={null}
        onSave={(values) => {
          mutate({
            values: {
              ...values,
              user_id: selectedRow?.user?.id,
              facility_id: selectedRow?.facility_id,
            },
            id: selectedRow?.id + "",
          })
        }}
        data={editRequestEditModal(selectedRow)}
        isLoading={isUpdating}
        service_type={selectedRow?.attendance?.[0]?.service_type}
      />
    </Flex>
  )
}
