import { theme } from "@project/common"
import styled, { css } from "styled-components"
export const StyledTopHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  .history_tbl_date {
    border-top: 1px solid ${theme.colors.border};
    padding-top: 4px;
  }
`
export const ResponsiveContainer = styled.div<{ $active: boolean }>`
  display: flex;
  gap: 16px 8px;
  align-items: center;
  height: auto;
  transition: all 0.2s ease-in-out;
  transform-origin: top;
  width: 100%;
  padding: 8px;
  .left_content {
    width: 25px;
  }
  ${(props) =>
    props?.$active &&
    css`
      border: 1px solid ${theme.colors.green2};
      padding: 8px;
      border-radius: 5px;
    `}
  .right_content {
    display: flex;
    flex: 1;
    border: 1px solid ${theme.colors.border};
    border-radius: 5px;
    .row {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      padding: 8px;
      .row__container {
        border: 1px solid ${theme.colors.border};
        border-radius: 5px;
        .cell {
          padding: 5px;
          white-space: pre-line;
        }
        .justify__center {
          text-align: center;
        }
        .cell__label {
          background-color: ${theme.colors.background};
          border-radius: 5px 5px 0px 0px;
          border-bottom: 1px solid ${theme.colors.border};
        }
        .cell__row__content {
          .cell__row {
            display: flex;
            width: 100%;
            .sub_cel__content {
              display: flex;
              flex: 1;
              align-items: center;
              justify-content: center;
              padding: 8px;
              &:first-child {
                border-right: 1px solid ${theme.colors.border};
              }
            }
            .sub_cell_label {
              background-color: ${theme.colors.background};
              border-bottom: 1px solid ${theme.colors.border};
              display: flex;
              flex: 1;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
      .row__cta {
        display: flex;
        justify-content: center;

        .cta {
          width: fit-content;
          border-bottom: 1px solid ${theme.colors.green2};
          span {
            color: ${theme.colors.green2};
          }
        }
      }
      @media screen and (max-width: ${theme.breakpoints.sm}) {
        .row__container {
          .cell {
            word-break: break-all !important;
          }
        }
      }
    }
  }
`

export const UnSelectedContent = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border: 1px solid ${theme.colors.border};
  border-radius: 5px;
  gap: 8px;
  padding: 8px;
  cursor: pointer;
  .dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${theme.colors.border};
  }
`
