// packages
import cuid from "cuid"
import dayjs, { Dayjs } from "dayjs"

// commons
import { keys } from "@project/common"

// types
import { HistoryTableData } from "../types"
import { UserServiceContentResultWithAdditionalData } from "../../../types"

export const generateFullDataSource = (
  respData: HistoryTableData[],
  currentDate: Dayjs,
) => {
  const totalDays = currentDate.daysInMonth() // total numbers of day in a current month
  const newDataArray: HistoryTableData[] = [...Array(totalDays)].map(
    (_, item) => {
      const day = item + 1

      const existingItem = respData?.find(
        (item) =>
          dayjs(item.date).date() === day &&
          dayjs(item.date).format("YYYY-MM") === currentDate.format("YYYY-MM"), // we need to check if current date matches the date from api [just in case]
      )

      if (existingItem && keys(existingItem).length > 0) {
        return existingItem
      }
      const uniqueId = cuid()
      const yearMonth = currentDate.format("YYYY-MM")

      return {
        id: "",
        key: uniqueId,
        physical_condition: null,
        work_detail_am: [],
        work_detail_pm: [],
        work_impression: "",
        remarks: "",
        date: `${yearMonth}-${day}`,
      }
    },
  )

  return newDataArray
}

export const generateFullCalendarDataSrc = (
  respData: UserServiceContentResultWithAdditionalData[],
  currentDate: Dayjs,
) => {
  const totalDays = currentDate.daysInMonth() // total numbers of day in a current month
  const newDataArray: Partial<UserServiceContentResultWithAdditionalData>[] = [
    ...Array(totalDays),
  ].map((_, item) => {
    const day = item + 1

    const existingItem = respData?.find(
      (item) =>
        dayjs(item.date).date() === day &&
        dayjs(item.date).format("YYYY-MM") === currentDate.format("YYYY-MM"), // we need to check if current date matches the date from api [just in case]
    )

    if (existingItem && keys(existingItem).length > 0) {
      return existingItem
    }
    const uniqueId = cuid()
    const yearMonth = currentDate.format("YYYY-MM")

    return {
      id: null,
      key: uniqueId,
      start_time: null,
      end_time: null,
      break_start_time: null,
      break_end_time: null,
      break_start_time2: null,
      break_end_time2: null,
      break_start_time3: null,
      break_end_time3: null,
      break_start_time4: null,
      break_end_time4: null,
      break_start_time5: null,
      break_end_time5: null,
      date: `${yearMonth}-${day}`,
    }
  })

  return newDataArray
}
