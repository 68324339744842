import React, { useState } from "react"
import {
  Content,
  ImageWrapper,
  PdfWrapper,
  StyledTodayProgramContainer,
} from "./TodayProgramContainer.style"
import { ContentWrapper } from "../../components/ContentWrapper"
import { useQuery } from "react-query"
import { createId } from "@paralleldrive/cuid2"
import { fetchProgramRecord } from "../../services"
import { Empty, Flex, Skeleton } from "antd"
import { Pagination, SelectInput } from "@project/common"
import ReactHtmlParser from "react-html-parser"
import { useTranslation } from "react-i18next"
import { SHORT_MONTH_OPTIONS } from "../../constants"
import { useRouter } from "next/router"
export const TodayProgramContainer = (): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const [values, setValues] = useState({
    page: 1,
    month: null,
  })
  const { isLoading, data: recordData } = useQuery({
    queryKey: ["adult-user-program-record", values],
    queryFn: () =>
      fetchProgramRecord({
        ...values,
        pageSize: 20,
      }),
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
  })
  return (
    <StyledTodayProgramContainer>
      <div className={"record_top_content"}>
        <SelectInput
          name={"name"}
          options={SHORT_MONTH_OPTIONS || []}
          width={"282px"}
          height={"40px"}
          placeholder={"---"}
          allowClear
          onChange={(val) =>
            setValues({
              ...values,
              month: val,
            })
          }
        />
        {isLoading ? (
          <Skeleton.Button active />
        ) : (
          <Pagination
            total={recordData?.count}
            current={1}
            pageSize={20}
            onChange={(val) => setValues({ ...values, page: val })}
          />
        )}
      </div>
      {isLoading ? (
        <Flex vertical gap={20}>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </Flex>
      ) : (
        <>
          {recordData?.count <= 0 ? (
            <Flex vertical>
              <Empty description={t("No Data Found")} />
            </Flex>
          ) : (
            <>
              {recordData?.data?.map((data) => (
                <div
                  key={createId()}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    router?.push(`/program-record/details/${data?.id}`)
                  }
                >
                  <ContentWrapper title={data?.article_title || ""}>
                    <Content>{ReactHtmlParser(data.article_content_1)}</Content>
                    {data.article_content_img_1 !== "" &&
                      (data?.article_content_img_1.startsWith("data:image") ? (
                        <ImageWrapper>
                          <img src={data.article_content_img_1} />
                        </ImageWrapper>
                      ) : (
                        <PdfWrapper>
                          <img
                            src={"/assets/icons/daily-report.svg"}
                            alt={"PDF"}
                            width={"30px"}
                          />
                          <div>
                            <a
                              target={"_blank"}
                              href={data?.article_content_img_1}
                              rel={"noopener noreferrer"}
                              download={data?.image1_filename}
                            >
                              <span className={"image-title"}>
                                {data?.image1_filename}
                              </span>
                            </a>
                          </div>
                        </PdfWrapper>
                      ))}
                  </ContentWrapper>
                </div>
              ))}
            </>
          )}
        </>
      )}
    </StyledTodayProgramContainer>
  )
}
