import {
  Box,
  Button,
  Grid,
  InputAreaField,
  Modal,
  RadioGroup,
  SERVICE_TYPE_VALUES,
  getEndTimeHour,
  getEndTimeMin,
  keys,
} from "@project/common"
import { Flex } from "antd"
import dayjs from "dayjs"
import { useFormik } from "formik"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { ATTENDANCE_UPDATE_REQUEST } from "../../../../../adult/constants"
import { FULL_DATE_FORMAT } from "../../../../constants"
import { useAuthContext } from "../../../../contexts/AuthProvider"
import { AttendanceRecUpdateReqPayload } from "../../../../types"
import { SelectTime } from "../../common/SelectTime"
import { RequestFormValidation } from "../../types"
import BreakTimeFields from "./components/BreakTimeFields"
import { REQUEST_TYPE } from "./constants"
import { GridContainer, Separator } from "./styles"
import { AttendanceRequestForm, EditRequestFormModalData } from "./types"
import {
  breakTimeRecordToArray,
  sanitizeFormValue,
} from "./utils/breakRecordToArray"

// Initial value for the request form
function getInitialVal(data: EditRequestFormModalData): AttendanceRequestForm {
  const todayDate = dayjs()
  const currentDate = dayjs(data?.date)

  const isBeforeToday = currentDate.isBefore(todayDate, "day")
  const isAfterToday = currentDate.isAfter(todayDate, "day")
  return {
    request_type: data?.request_type
      ? data?.request_type
      : isAfterToday
        ? 2
        : isBeforeToday
          ? 1
          : 1,
    new_check_in_time: data?.start_time,
    new_check_out_time: data?.end_time,
    reason: data.reason || "",
    break_time_list: breakTimeRecordToArray(data),
    date: data.date,
  }
}

export const EditRequestFormModal = ({
  data,
  onSave,
  isLoading,
  requestType = 1,
  service_type,
  ...props
}: {
  open: boolean
  onCancel: () => void
  title: string | React.ReactNode
  footer: null | React.ReactNode
  data: EditRequestFormModalData
  isLoading?: boolean
  service_type: string | number
  onSave: (data: AttendanceRecUpdateReqPayload) => void
  requestType: 1 | 2 | "both"
}): JSX.Element => {
  const [loadingBtn] = React.useState(isLoading || false)
  const { t } = useTranslation()
  const { userInformation } = useAuthContext()
  const formik = useFormik<AttendanceRequestForm>({
    initialValues: getInitialVal(data),
    validationSchema: RequestFormValidation,
    onSubmit: (values) => {
      // setLoadingBtn(true)
      onSave({
        ...sanitizeFormValue(values),
        facility_id: userInformation?.active_contract?.facility_id,
      })
    },
  })

  const { handleSubmit, values, setFieldValue, errors, touched } = formik

  useEffect(() => {
    if (keys(data).length > 0) {
      formik.setValues(getInitialVal(data))
    }
  }, [data?.request_type, data?.date])

  const filteredRequestTypeOptions =
    +service_type === SERVICE_TYPE_VALUES.TYPE_1
      ? REQUEST_TYPE
      : REQUEST_TYPE.slice(0, 1)

  return (
    <Modal
      {...props}
      width={1329}
      bodyPadding={"15px"}
      className={"request-modal"}
      destroyOnClose
    >
      <form onSubmit={handleSubmit} className={"req-form"}>
        <div>
          <Grid
            labelContent={t("Request Type")}
            background
            labelSpan={
              values?.request_type === ATTENDANCE_UPDATE_REQUEST ? 8 : 6
            }
          >
            <RadioGroup
              value={values?.request_type || null}
              name={"request_type"}
              options={filteredRequestTypeOptions.map((item) => {
                if (requestType === 1 && item.value === 2) {
                  return { ...item, disabled: true }
                }
                if (requestType === 2 && item.value === 1) {
                  return { ...item, disabled: true }
                }
                return item
              })}
              onChange={({ target: { value } }) => {
                setFieldValue("request_type", value)
              }}
            />
          </Grid>

          <Grid
            labelContent={t("Date")}
            background
            labelSpan={
              values?.request_type === ATTENDANCE_UPDATE_REQUEST ? 8 : 6
            }
          >
            {dayjs(values?.date).format(FULL_DATE_FORMAT)}
          </Grid>

          {values?.request_type === ATTENDANCE_UPDATE_REQUEST && (
            <>
              <Grid
                labelContent={t("Registered checkin and checkout time")}
                background
                labelSpan={
                  values?.request_type === ATTENDANCE_UPDATE_REQUEST ? 8 : 6
                }
                textAlign={"left"}
              >
                <Box display={"flex"} align={"center"} gap={48} wrap={"wrap"}>
                  <Flex align={"center"} gap={8}>
                    <span>
                      {t("Attendance  ")}
                      {":"}
                    </span>
                    <span>
                      {data.registered_start_time}
                      {" ~ "}
                      {data.registered_end_time}
                    </span>
                  </Flex>

                  <Flex align={"center"} gap={8}>
                    <span>
                      {t("Break Time")}
                      {":"}
                    </span>
                    <span>{data.registered_total_break_time}</span>
                  </Flex>
                </Box>
              </Grid>

              <GridContainer>
                <Grid
                  labelContent={t("New Check In and Check out")}
                  background
                  labelSpan={
                    values?.request_type === ATTENDANCE_UPDATE_REQUEST ? 8 : 6
                  }
                  className={"alter-attendance-grid"}
                >
                  <Box
                    display={"flex"}
                    wrap={"wrap"}
                    className={"break-time__section 924"}
                  >
                    {/* Attendance inputs */}
                    <div className={"grid-child"} style={{ flex: 1 }}>
                      <Box component={"div"} mb={8}>
                        {t("Attendance  ")}
                        {":"}
                      </Box>
                      <Flex align={"center"} gap={16} wrap={"wrap"}>
                        <SelectTime
                          value={values?.new_check_in_time}
                          onTimeChange={(val) => {
                            setFieldValue("new_check_in_time", val)
                            setFieldValue("new_check_out_time", null)
                          }}
                          onBlur={() => null}
                        />
                        {"~"}
                        <SelectTime
                          disabled={!values?.new_check_in_time}
                          value={values?.new_check_out_time}
                          onTimeChange={(val) => {
                            const time = val?.split(":")
                            const mins = getEndTimeMin(
                              values?.new_check_in_time,
                              val,
                            )
                            const end_min =
                              time[1] === "00" || !time[1]
                                ? mins[1].value
                                : time[1]
                            setFieldValue(
                              "new_check_out_time",
                              `${time[0]}:${end_min}`,
                            )
                          }}
                          END_HOURS_OPTIONS={
                            values?.new_check_in_time
                              ? getEndTimeHour(values?.new_check_in_time)
                              : []
                          }
                          END_MINUTES_OPTIONS={
                            getEndTimeMin(
                              values?.new_check_in_time,
                              values?.new_check_out_time,
                            ) || []
                          }
                          onBlur={() => null}
                        />
                      </Flex>
                    </div>

                    <Separator />

                    {/* Break time inputs */}
                    <div className={"grid-child"} style={{ flex: 1 }}>
                      <Box component={"div"} mb={8}>
                        {t("Break time")}
                        {":"}
                        <BreakTimeFields formik={formik} />
                      </Box>
                    </div>
                  </Box>
                </Grid>
              </GridContainer>
            </>
          )}

          <Grid
            labelContent={t("Reason")}
            background
            required
            labelSpan={
              values?.request_type === ATTENDANCE_UPDATE_REQUEST ? 8 : 6
            }
          >
            <InputAreaField
              placeholder={"--"}
              rows={3}
              error={touched?.reason && errors?.reason}
              value={values?.reason || null}
              onChange={({ target: { value } }) =>
                setFieldValue("reason", value)
              }
            />
          </Grid>
        </div>

        <br />

        <Flex wrap={"wrap"} gap={16}>
          <Button
            btnText={t("Cancel")}
            shape={"round"}
            onClick={props?.onCancel}
            disabled={isLoading || loadingBtn}
          />
          <Button
            btnText={t("Save")}
            shape={"round"}
            type={"primary"}
            htmlType={"submit"}
            isLoading={isLoading || loadingBtn}
          />
        </Flex>
      </form>
    </Modal>
  )
}
