import { t } from "i18next"

export const REQUEST_TYPE = [
  {
    label: t("Attendance Edit Request"),
    value: 1,
  },
  {
    label: t("Paid Leave Request"),
    value: 2,
  },
]
