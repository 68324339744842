import { t } from "i18next"
import * as Yup from "yup"

export const UpdatePasswordSchema = Yup.object().shape({
  old_password: Yup.string().required(t("Required")),
  new_password: Yup.string().required(t("Required")),
  confirm_password: Yup.string()
    .required(t("Required"))
    .min(6, t("6 or more alphanumeric characters"))
    .max(16, t("Please enter number within 16 characters."))
    .oneOf([Yup.ref("new_password")], t("The password does not match")),
})
