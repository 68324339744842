import { Box, theme } from "@project/common"
import styled from "styled-components"

export const GridContainer = styled.div`
  .alter-attendance-grid {
    border-bottom: none;
    .ant-col.col__content {
      padding: 0 16px;

      .break-time__section {
        .grid-child {
          padding: 16px 0px;
        }

        @media screen and (max-width: 924px) {
          flex-direction: column;
          .grid-child {
            padding: 8px 0px;
          }
        }
      }
      .break-time_section {
        border-left: 1px solid ${theme.colors.border};
        padding-left: 16px;
        margin-left: 16px;
      }
    }
    @media screen and (max-width: 924px) {
      .ant-col.col__content {
        padding: 0;
      }
    }
  }
`

export const BreakTimeFieldsContainer = styled(Box)`
  .delete-button {
    padding: 4px 6px;
    color: ${theme.colors.error};
    text-decoration: underline;
    &:hover {
      color: ${theme.colors.error} !important;
      border: 1px solid transparent !important;
      filter: brightness(110%);
    }
  }
  .footer-action-btn {
    width: 100%;
    justify-content: space-between;
    .alert-text {
      color: ${theme.colors.error};
      font-size: 12px;
    }
  }
`

export const Separator = styled.div`
  width: 1px;
  background: ${theme.colors.border};
  margin-inline: 16px;

  @media screen and (max-width: 924px) {
    display: none;
  }
`
