import dayjs from "dayjs"
import { AddDailyRecordFormType } from "./types"
import { t } from "i18next"

export const PHYSICAL_CONDITION = [
  {
    label: t("Good"),
    value: "1",
  },
  {
    label: t("Normal"),
    value: "2",
  },
  {
    label: t("Bad"),
    value: "3",
  },
]

export const PHYSICAL_CONDITION_OBJ = {
  "1": "Good",
  "2": "Normal",
  "3": "Bad",
}

export const DR_FORM_INITIAL_VAL: AddDailyRecordFormType = {
  date: dayjs(),
  note: "",
  physical_condition: "1",
  work_detail_am: [],
  work_detail_pm: [],
  work_impression: "",
}
