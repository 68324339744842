import { useContext, useMemo, useRef } from "react"

// packages
import { t } from "i18next"
import { useRouter } from "next/router"

// common | components

import {
  Box,
  scrollToSelectedElement,
  SERVICE_TYPE_VALUES,
  useUpdateSearchParams,
} from "@project/common"
import { TabsV2 } from "../../components"

import {
  CalenderTabContent,
  EditRequestTabsContent,
  HistoryTabContent,
  LeaveRequestTabContent,
} from "./TabContents"
import { AddDailyRecordForm } from "./TabContents/AddDailyRecordForm"
import { AuthContext } from "../../contexts/AuthProvider"
import { TabsProps } from "antd"

const HISTORY_TAB = {
  key: "history",
  label: t("History"),
  children: <HistoryTabContent />,
}

const CALENDAR_TAB = {
  key: "calender",
  label: t("Calender"),
  children: <CalenderTabContent />,
}

const EDIT_TAB = {
  key: "edit-request-list",
  label: t("Edit request List"),
  children: <EditRequestTabsContent />,
}

const LEAVE_TAB = {
  key: "leave-request",
  label: t("Leave Request"),
  children: <LeaveRequestTabContent />,
}

export const DailyRecord = () => {
  const { query } = useRouter()
  const contentRef = useRef<HTMLDivElement>(null)
  const [updateParams] = useUpdateSearchParams()

  const { userInformation } = useContext(AuthContext)

  const ITEMS: TabsProps["items"] = useMemo(() => {
    if (
      userInformation?.user?.user_certificate?.service_type ==
      SERVICE_TYPE_VALUES.TYPE_1
    ) {
      return [HISTORY_TAB, CALENDAR_TAB, EDIT_TAB, LEAVE_TAB]
    }
    return [HISTORY_TAB, CALENDAR_TAB, EDIT_TAB]
  }, [userInformation])

  return (
    <div>
      <AddDailyRecordForm
        onCancel={() => null}
        scrollTo={() => {
          scrollToSelectedElement(contentRef)
        }}
      />

      <Box mt={30} ref={contentRef}>
        <TabsV2
          defaultActiveKey={"history"}
          type={"card"}
          size={"large"}
          items={ITEMS}
          activeKey={query?.daily_record as string}
          onChange={(key) => updateParams({ ...query, daily_record: key }, "/")}
        />
      </Box>
    </div>
  )
}
