import styled, { css } from "styled-components"

export const StyledTodayProgramContainer = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  .record_top_content {
    display: flex;
    column-gap: 16px;
    row-gap: 16px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
`
const HStyle = css`
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
`
export const Content = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  word-break: break-all;
  white-space: pre-wrap;
  padding-bottom: 15px;
  h1 {
    display: block;
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    ${HStyle};
  }
  h2 {
    display: block;
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    ${HStyle};
  }
  h3 {
    display: block;
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    ${HStyle};
  }
  h4 {
    display: block;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    ${HStyle};
  }
  h5 {
    display: block;
    font-size: 0.83em;
    margin-top: 1.67em;
    margin-bottom: 1.67em;
    ${HStyle};
  }
  h6 {
    display: block;
    font-size: 0.67em;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
    ${HStyle};
  }
  p {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
  }
  & img {
    width: 100%;
    max-width: 300px;
    object-fit: contain;
  }
`
export const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-bottom: 20px;

  & > img {
    width: 100%;
    max-width: 300px;
    object-fit: contain;
  }
`
export const PdfWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  border: 1px solid #d2d1d1;
  border-radius: 5px;
  padding: 4px 12px;
  width: max-content;
  @media (max-width: 767px) {
    width: auto;
    & .image-title {
      word-break: break-all;
    }
  }
`
