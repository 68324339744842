// packages
import * as Yup from "yup"
import { t } from "i18next"

export type ConsumerLoginProps = {
  handleSubmit: (values: LoginAttrib) => void
  loading: boolean
}
export const LoginValidationSchema = Yup.object().shape({
  email: Yup.string().required(t("Enter Email or id")),
  password: Yup.string().required(t("Enter Password")),
})

export type LoginAttrib = Yup.InferType<typeof LoginValidationSchema>
