import {
  Calendar,
  omit,
  scrollToSelectedElement,
  theme,
  useUpdateSearchParams,
} from "@project/common"
import dayjs, { Dayjs } from "dayjs"
import React, { useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"
import { FULL_DATE_FORMAT_EN } from "../../../../constants"
import { EditRequestFormModal } from "../TabContentModals/EditRequestFormModal"
import { CalenderCellContent } from "./CalenderCellContent"

import { Spin } from "antd"
import { useRouter } from "next/router"
import { useAuthContext } from "../../../../contexts/AuthProvider"
import {
  useDailyRecordMutation,
  useDailyRecordMutationRequest,
} from "../../../../hooks"
import { AttendanceRecordData } from "../../../../services"
import {
  AttendanceRecUpdateReqPayload,
  UserServiceContentResultWithAdditionalData as SingleAttendanceData,
} from "../../../../types"
import { calendarEditRequestEditModal } from "../TabContentModals/utils/editRequestFormMapper"

export const CalenderTabContentCalendarView = ({
  attendanceRecord,
  yearMonth,
  isLoading,
}: {
  isLoading: boolean
  attendanceRecord: AttendanceRecordData
  yearMonth: Dayjs
}): JSX.Element => {
  const { query } = useRouter()

  const { t } = useTranslation()
  const [updateParams] = useUpdateSearchParams()

  const [openFormModal, setOpenFormModal] = React.useState<boolean>(false)
  const [selectedOne, setSelectedOne] =
    React.useState<SingleAttendanceData>(null)
  const calendarRef = useRef(null)

  const todayDate = dayjs()

  const currentDate = dayjs(selectedOne?.date)

  const isAfterToday = currentDate.isAfter(todayDate, "day")
  const isBeforeToday = currentDate.isBefore(todayDate, "day")

  const { userInformation, queryClient } = useAuthContext()

  const filterQuery = () => {
    updateParams({ ...omit(query, ["edit_request_from"]) }, "/")
  }

  const onSuccess = () => {
    setOpenFormModal(false)
    setSelectedOne(null)
    filterQuery()
    queryClient.invalidateQueries(["all-attendance-record-my-month"])
  }

  const runOnError = () => {
    setOpenFormModal(false)
    setSelectedOne(null)
    filterQuery()
  }

  const onModalClose = () => {
    setOpenFormModal(false)
    setSelectedOne(null)
    filterQuery()
  }

  const { updateRecordData: makeAttendanceChangeRequest } =
    useDailyRecordMutationRequest({
      onSuccess,
      runOnError,
    })

  const { mutate: updateAttendanceRequest } = useDailyRecordMutation({
    onSuccess,
    runOnError,
  })

  // If user is trying to request for addendance update from main_controller i.e. from top header.
  // then we immediately open up modal for today's date.
  React.useEffect(() => {
    if (
      query?.edit_request_from === "main_controller" &&
      attendanceRecord?.data?.length > 0
    ) {
      const todayDate = dayjs().format("YYYY-MM-DD")

      const matchTodaysData = attendanceRecord?.data?.find((item) => {
        return dayjs(item?.date).format("YYYY-MM-DD") === todayDate
      })

      scrollToSelectedElement(calendarRef)

      if (!matchTodaysData) {
        filterQuery()
        return
      }

      setSelectedOne(matchTodaysData)
      setOpenFormModal(true)
    }
  }, [attendanceRecord, query])

  const memoizedModal = useMemo(() => {
    if (openFormModal) {
      const unregistered =
        isBeforeToday &&
        (!selectedOne.attendance_type || selectedOne.attendance_type == "0") &&
        (!selectedOne?.end_time || !selectedOne?.start_time)

      return (
        <Spin spinning={isLoading}>
          <EditRequestFormModal
            title={t("Request Form")}
            requestType={
              unregistered
                ? "both"
                : isBeforeToday
                  ? 1
                  : isAfterToday
                    ? 2
                    : "both"
            }
            open={openFormModal}
            onCancel={() => onModalClose()}
            footer={null}
            onSave={(values: Partial<AttendanceRecUpdateReqPayload>) => {
              selectedOne.user_daily_record_request.id
                ? updateAttendanceRequest({
                    values: {
                      ...values,
                      user_id: userInformation?.user?.id,
                    },
                    id: selectedOne?.user_daily_record_request?.id + "",
                  })
                : makeAttendanceChangeRequest({
                    ...values,
                    user_id: userInformation?.user?.id,
                    facility_id: selectedOne?.facility_id,
                  })
              updateParams({ ...omit(query, ["edit_request_from"]) }, "/")
            }}
            data={calendarEditRequestEditModal(selectedOne)}
            service_type={selectedOne?.service_type}
            isLoading={isLoading}
          />
        </Spin>
      )
    }
  }, [openFormModal, isLoading])

  return (
    <div ref={calendarRef}>
      <Spin spinning={isLoading}>
        <Calendar
          value={yearMonth}
          headBg={theme.colors["t-head"]}
          dateStyle={"FULL"}
          cellRender={(date: Dayjs) => {
            const currentData = attendanceRecord?.data?.find(
              (record) =>
                dayjs(record?.date).format(FULL_DATE_FORMAT_EN) ===
                dayjs(date).format(FULL_DATE_FORMAT_EN),
            )
            return {
              isHoliday: false,
              content: currentData ? (
                <CalenderCellContent
                  contentData={currentData || null}
                  onEditRequest={() => {
                    setOpenFormModal(true)
                    setSelectedOne(currentData || null)
                  }}
                />
              ) : null,
            }
          }}
        />
      </Spin>

      {memoizedModal}
    </div>
  )
}
