import React, { useEffect, useState } from "react"

// packages
import { Flex } from "antd"

// commons | components
import {
  Box,
  CALENDAR_HOURS_MINUTES,
  ErrorMassage,
  SelectInput,
  getHrMin,
} from "@project/common"

// types
export type SelectTimeProps = {
  value: string
  onTimeChange: (val: string) => void
  disabled?: boolean
  useDebounce?: boolean
  error?: string
  onBlur?: () => void
  END_HOURS_OPTIONS?: any[]
  END_MINUTES_OPTIONS?: any[]
}

// !## MAIN COMPONENT
export const SelectTime: React.FC<SelectTimeProps> = ({
  value,
  error,
  onBlur,
  disabled,
  useDebounce,
  onTimeChange,
  END_HOURS_OPTIONS,
  END_MINUTES_OPTIONS,
}) => {
  const [currentValue, setCurrentValue] = useState<string>(value)

  const handleCurrValChange = (newVal: string) => {
    setCurrentValue(newVal)
    if (!useDebounce) {
      onTimeChange(newVal)
    }
  }

  useEffect(() => {
    setCurrentValue(value)
  }, [value])
  return (
    <Box>
      <Flex align={"center"} gap={"8px"}>
        <Box maw={58} miw={58}>
          <SelectInput
            name={"hr"}
            width={"58px"}
            placeholder={"--"}
            onBlur={() => onBlur()}
            value={getHrMin(currentValue)?.hr}
            options={
              END_HOURS_OPTIONS?.length
                ? END_HOURS_OPTIONS
                : CALENDAR_HOURS_MINUTES.hours
            }
            disabled={disabled}
            onChange={(hr) => {
              if (!getHrMin(currentValue)?.min) {
                handleCurrValChange(hr ? `${hr}:00` : "")
                return
              }

              handleCurrValChange(
                hr ? `${hr}:${getHrMin(currentValue)?.min}` : "",
              )
            }}
          />
        </Box>
        <span>{":"}</span>
        <Box maw={58} miw={58}>
          <SelectInput
            name={"min"}
            width={"58px"}
            onBlur={() => onBlur()}
            placeholder={"--"}
            value={getHrMin(currentValue)?.min}
            disabled={disabled || !getHrMin(currentValue)?.hr}
            options={
              END_MINUTES_OPTIONS?.length
                ? END_MINUTES_OPTIONS
                : CALENDAR_HOURS_MINUTES.minutes
            }
            onChange={(min) => {
              handleCurrValChange(`${getHrMin(currentValue)?.hr}:${min}`)
            }}
          />
        </Box>
      </Flex>
      {error && <ErrorMassage message={error} />}
    </Box>
  )
}
