import { theme } from "@project/common"
import styled from "styled-components"
export const StyledTableWrapper = styled.div`
  .ant-table-container {
    width: 100%;
    overflow: auto;
  }
  table {
    thead {
      tr {
        th.th_week_with_border {
          /* background-color: red; */
          border-right: 2px solid ${theme.colors.text} !important;
        }
        th.not_to_be_shown {
          display: none;
        }
      }
    }
    tbody {
      tr {
        td.th_week_with_border {
          /* background-color: red; */
          border-right: 2px solid ${theme.colors.text} !important;
        }
      }
    }
    tfoot {
      border-collapse: collapse;
      tr {
        td {
          padding: 0 !important;
          border: 1px solid #000 !important;
          &:not(:last-child) {
            border-right: 0 !important;
          }
          &:first-child {
            border-left: 0 !important;
          }
          .t_cell_content {
            padding: 6px !important;
          }
        }
        tr.t_foot_extra_content {
          background-color: red;
        }
      }
      &::before {
        line-height: 0.8em;
        content: " ";
        color: red;
        display: block;
        height: 0.4em;
      }
    }
  }

  @media print {
    table {
      /* table-layout: fixed !important; */
      tr.ant-table-row {
        page-break-inside: avoid;
      }
      th.ant-table-cell,
      td.ant-table-cell {
        padding: 4px;
        font-size: 8pt;
        div,
        span {
          font-size: 8pt;
        }
      }
      tfoot {
        display: table-row-group;
      }
    }
  }
`

export const SignatureContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon-content {
    position: relative;
    cursor: pointer;
    background: ${theme.colors.border};
    height: 20px;
    width: 20px;
    border-radius: 2px;
    &:hover {
      background: ${theme.colors.accent};
    }
    .anticon {
      position: absolute;
      left: 1px;
      bottom: 1px;
    }
  }
`

export const TFootExtraContent = styled.div`
  display: flex;
  .cell__key {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0.3;
    padding: 2px;
    font-weight: 500;
  }
  .cell__values_content {
    flex: 1;
    display: flex;
    flex-direction: column;
    .cell__values {
      display: flex;
      flex: 1;
      flex-wrap: nowrap;
      width: 100%;
      .cell_value {
        border: thin dashed ${theme.colors.text};
        border-top: 0;
        border-right: 0;
        min-width: 40px;
        padding: 2px;
        width: 100%;
      }
      &:last-child {
        .cell_value {
          border-bottom: 0;
        }
      }
    }
  }
`
