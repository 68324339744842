import { useState } from "react"

import { DatePicker, useUpdateSearchParams } from "@project/common"
import { Flex, Spin, Typography } from "antd"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import { FULL_DATE_FORMAT_EN, YM_FORMAT } from "../../../../constants"
import { useAuthContext } from "../../../../contexts/AuthProvider"
import { PHYSICAL_CONDITION } from "../../constants"
import { generateFullDataSource } from "../../utils/generateFullDataSource"
import { HistoryTabDailyRecordEditModal } from "../TabContentModals/HistoryTabDailyRecordEditModal"
import {
  ResponsiveContainer,
  StyledTopHeader,
  UnSelectedContent,
} from "./ResponsiveHistoryTabContent.style"
export const ResponsiveHistoryTabContent = ({
  dailyRecords,
  isLoading,
}: {
  dailyRecords: any[]
  isLoading: boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState(dayjs().format(FULL_DATE_FORMAT_EN))
  const { query } = useRouter()
  const [updateParams] = useUpdateSearchParams()
  const { workData } = useAuthContext()
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})
  const currentDate = query?.date
    ? dayjs(query?.date as string).isValid
      ? dayjs(query?.date as string)
      : dayjs()
    : dayjs()
  const [dateValue, setDateValue] = useState(currentDate || dayjs())

  return (
    <>
      <Flex vertical gap={16}>
        <StyledTopHeader>
          <h4 className={"title-header"}>
            {t("{{engDate}} Daily record history", {
              engDate: currentDate.locale("en").format("MMMM, YYYY"),
              jaDate: currentDate.format("YYYY年MM月"),
            })}
          </h4>
          {/* <MonthPagination
            className={"month-pagination"}
            currentMonth={currentDate.month()}
            currentYear={currentDate.year()}
            width={"80px"}
            showDate={false}
            onMonthChange={(_, __, date) => {
              updateParams({ ...query, date: date.format("YYYY-MM") }, "/")
            }}
          /> */}
          <div className={"history_tbl_date"}>
            <DatePicker
              date={dateValue || dayjs()}
              format={YM_FORMAT}
              picker={"month"}
              popupClassName={"date-picker-popup-height-responsive"}
              onDateChange={(date) => {
                setDateValue(date)
                updateParams({ ...query, date: date.format("YYYY-MM") }, "/")
              }}
            />
          </div>
        </StyledTopHeader>
        <Spin spinning={isLoading}>
          <Flex vertical gap={8}>
            {generateFullDataSource(dailyRecords || [], dayjs())?.map(
              (source) => {
                const isActive = source?.date === selected
                const condition = PHYSICAL_CONDITION?.find(
                  (v) => v?.value === `${source?.physical_condition}`,
                )?.label
                return (
                  <ResponsiveContainer key={source?.date} $active={isActive}>
                    <div className={"left_content"}>
                      <div>{dayjs(source?.date).format("dd")}</div>
                      <div>{dayjs(source?.date).format("DD")}</div>
                    </div>
                    {source?.work_impression || isActive ? (
                      <>
                        <div className={"right_content"}>
                          <div className={"row"}>
                            <div className={"row__container"}>
                              <div className={"cell cell__label"}>
                                {t("Status")}
                              </div>
                              <div className={"cell cell__value"}>
                                {t(condition)}
                              </div>
                            </div>
                            <div className={"row__container"}>
                              <div
                                className={"cell cell__label justify__center"}
                              >
                                {t("Work Detail")}
                              </div>
                              <div className={"cell__row__content"}>
                                <div className={"cell__row"}>
                                  <div
                                    className={
                                      "sub_cell_label sub_cel__content justify__center"
                                    }
                                  >
                                    {t("AM")}
                                  </div>
                                  <div
                                    className={
                                      "sub_cell_label sub_cel__content justify__center"
                                    }
                                  >
                                    {t("PM")}
                                  </div>
                                </div>
                                <div className={"cell__row"}>
                                  <div
                                    className={
                                      "sub_cell_value sub_cel__content"
                                    }
                                  >
                                    {source?.work_detail_am?.map((id) => (
                                      <>
                                        {workData.find((v) => v.value === id)
                                          ?.label || ""}
                                        <br />
                                      </>
                                    ))}
                                  </div>
                                  <div
                                    className={
                                      "sub_cell_value sub_cel__content"
                                    }
                                  >
                                    {source?.work_detail_pm?.map((id) => (
                                      <>
                                        {workData.find((v) => v.value === id)
                                          ?.label || ""}
                                        <br />
                                      </>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={"row__container"}>
                              <div className={"cell cell__label"}>
                                {t("Impressions")}
                              </div>
                              <div className={"cell cell__value"}>
                                {source?.work_impression || ""}
                              </div>
                            </div>
                            <div className={"row__container"}>
                              <div className={"cell cell__label"}>
                                {t("Remarks")}
                              </div>
                              <div className={"cell cell__value"}>
                                {source?.remarks || ""}
                              </div>
                            </div>
                            <div
                              className={"row__cta"}
                              onClick={() => {
                                setSelectedRow(source)
                                setIsEditModalOpen(true)
                              }}
                            >
                              <div className={"cta"}>
                                <img
                                  src={"/assets/icons/pencil-outline-green.svg"}
                                  alt={"icon"}
                                />
                                <Typography.Text>{t("Edit")}</Typography.Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <UnSelectedContent
                          onClick={() => setSelected(source?.date)}
                        >
                          <div className={"dot"}></div>
                          <div className={"dot"}></div>
                          <div className={"dot"}></div>
                        </UnSelectedContent>
                      </>
                    )}
                  </ResponsiveContainer>
                )
              },
            )}
          </Flex>
        </Spin>
      </Flex>

      {isEditModalOpen && (
        <HistoryTabDailyRecordEditModal
          title={t("Edit")}
          open={isEditModalOpen}
          onCancel={() => {
            setSelectedRow({})
            setIsEditModalOpen(false)
          }}
          footer={null}
          data={selectedRow}
          onSave={() => {
            return null
          }}
        />
      )}
    </>
  )
}
