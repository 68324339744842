import {
  UserDailyRecordRequestWithAttendance as ChangeAttendanceRecReq,
  UserServiceContentResultWithAdditionalData as SingleAttendanceData,
} from "../../../../../types"
import { breakTimeInString, getSumOfAllBreakTimes } from "../../../../../utils"
import { EditRequestFormModalData } from "../types"

export const calendarEditRequestEditModal = (
  attendanceData: SingleAttendanceData,
): EditRequestFormModalData => {
  const requestData = attendanceData?.user_daily_record_request
  return {
    end_time: requestData?.new_check_out_time,
    start_time: requestData?.new_check_in_time,
    reason: requestData?.reason,
    request_type: requestData?.request_type,
    date: attendanceData?.date,
    break_start_time: requestData?.new_break_start_time,
    break_end_time: requestData?.new_break_end_time,
    break_start_time2: requestData?.new_break_start_time2,
    break_end_time2: requestData?.new_break_end_time2,
    break_start_time3: requestData?.new_break_start_time3,
    break_end_time3: requestData?.new_break_end_time3,
    break_start_time4: requestData?.new_break_start_time4,
    break_end_time4: requestData?.new_break_end_time4,
    break_start_time5: requestData?.new_break_start_time5,
    break_end_time5: requestData?.new_break_end_time5,
    registered_total_break_time: breakTimeInString(
      getSumOfAllBreakTimes(attendanceData, attendanceData?.date),
    ),
    registered_start_time: attendanceData?.start_time,
    registered_end_time: attendanceData?.end_time,
  }
}

export const editRequestEditModal = (
  data: ChangeAttendanceRecReq,
): EditRequestFormModalData => {
  return {
    end_time: data?.new_check_out_time,
    start_time: data?.new_check_in_time,
    reason: data?.reason,
    date: data?.date,
    request_type: data?.request_type,
    break_start_time: data?.new_break_start_time,
    break_end_time: data?.new_break_end_time,
    break_start_time2: data?.new_break_start_time2,
    break_end_time2: data?.new_break_end_time2,
    break_start_time3: data?.new_break_start_time3,
    break_end_time3: data?.new_break_end_time3,
    break_start_time4: data?.new_break_start_time4,
    break_end_time4: data?.new_break_end_time4,
    break_start_time5: data?.new_break_start_time5,
    break_end_time5: data?.new_break_end_time5,
    registered_end_time: data?.attendance?.[0]?.end_time,
    registered_start_time: data?.attendance?.[0]?.start_time,
    registered_total_break_time: breakTimeInString(
      getSumOfAllBreakTimes(data?.attendance?.[0], data?.attendance?.[0]?.date),
    ),
  }
}
