import { theme } from "@project/common"
import styled from "styled-components"

export const ContentWrapper = styled.div`
  padding: 7px;
  .time__list__container {
    background-color: ${theme.colors.gray11};
    border-radius: 0px 0px 5px 5px;
    .time__content {
      padding: 6px 8px;
      border-radius: 5px;

      font-weight: 500;
      font-size: 12px;
      .ant-flex {
        font-size: inherit;
        .time__content__label {
          font-size: inherit;
        }
      }
    }

    .time__absent {
      background-color: ${theme.colors["error-light"]};
      color: ${theme.colors.error};
    }

    .time__unregistered {
      background-color: ${theme.colors["orange2"]};
      color: ${theme.colors.white};
    }

    .time__paid-leave-for-future {
      background-color: ${theme.colors["secondary"]};
      color: ${theme.colors.white};
    }

    .time__present,
    .time__attendance-edit-req-waiting,
    .time__paid-leave-waiting,
    .time__attendance-edit-req-accepted {
      background-color: ${theme.colors.green2};
      color: ${theme.colors.white};
    }

    .time__rejected,
    .time__paid-leave-rejected,
    .time__attendance-edit-req-rejected {
      background-color: ${theme.colors.error_dark};
      color: ${theme.colors.white};
    }

    .time__paid-leave-accepted {
      background-color: ${theme.colors.blueLight};
      color: ${theme.colors.white};
    }

    .list {
      display: flex;
      flex-direction: column;
      row-gap: 6px;
      padding: 5px 8px;
      .list__content {
        display: flex;
        align-items: center;
        .list__dot {
          height: 10px;
          width: 10px;
          border-radius: 50%;
          margin-right: 4px;
        }
        .green {
          background-color: ${theme.colors.green2};
        }
        .orange {
          background-color: ${theme.colors.orange2};
        }
      }
    }
  }
  .action__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    cursor: pointer;
    .contain {
      img {
        margin-right: 5px;
      }
      width: fit-content;
      border-bottom: 2px solid ${theme.colors.green2};
      span {
        color: ${theme.colors.green2};
      }
    }
  }

  .status_pill {
    font-size: 12px;
    padding: 2.5px 8px;
    text-align: center;
    margin-top: 10px;
    border-radius: 4px;
    &.pending-status-pills {
      color: ${theme.colors.info};
      border: 1px solid ${theme.colors.info};
      background: rgba(210, 109, 16, 0.08);
    }
    &.absent-status-pills {
      color: ${theme.colors.error};
      border: 1px solid ${theme.colors.error};
      background: rgba(224, 0, 0, 0.08);
    }
    &.accepted-status-pills {
      color: ${theme.colors.white};
      border: 1px solid ${theme.colors.secondary};
      background: ${theme.colors.secondary};
    }
  }
  .future_date_card_header {
    background: ${theme.colors.secondary} !important;
    color: ${theme.colors.white} !important;
  }
`
