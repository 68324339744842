import {
  Box,
  USER_ATTENDANCE_VALUES,
  breakTimeInString,
  pick,
  theme,
} from "@project/common"
import { Button, Flex, Popconfirm, Typography } from "antd"
import dayjs from "dayjs"
import { t } from "i18next"
import React from "react"
import styled from "styled-components"
import {
  APPROVED_STATUS,
  ATTENDANCE_UPDATE_REQUEST,
  PAID_LEAVE_REQUEST,
  REJECTED_STATUS,
  WAITING_STATUS,
} from "../../../../adult/constants"
import { UserServiceContentResultWithAdditionalData } from "../../../types"
import { calcTotalBreakTime } from "../../../utils"
import { ALL_BREAK_TIMES_KEY } from "../../../utils/constants"

export const ActionButton = styled(Button)<{ $variant: "error" | "action" }>`
  padding: 0;
  height: auto;
  .text {
    border-bottom: 1px solid
      ${({ $variant }) =>
        $variant ? theme.colors[$variant] : theme.colors["action"]};
    display: flex;
    align-items: center;
    gap: 2px;
    .ant-typography {
      color: ${({ $variant }) =>
        $variant ? theme.colors[$variant] : theme.colors["action"]};
    }
  }
`

export const CalenderActionButton = ({
  contentData,
  onEditRequest,
}: {
  contentData: Partial<UserServiceContentResultWithAdditionalData>
  onEditRequest: () => void
}) => {
  if (
    contentData.user_daily_record_request?.status === APPROVED_STATUS &&
    contentData.user_daily_record_request?.id > 0
  ) {
    return (
      <div className={"status_pill accepted-status-pills "}>
        {t("Accepted")}
      </div>
    )
  }

  if (
    (contentData.id &&
      contentData.user_daily_record_request.id > 0 &&
      contentData.user_daily_record_request?.status !== REJECTED_STATUS &&
      contentData.user_daily_record_request?.status !== WAITING_STATUS) ||
    (!contentData.id && !contentData.user_daily_record_request?.id) ||
    (contentData.id && !contentData.user_daily_record_request?.id)
  ) {
    return (
      <div className={"action__container"} onClick={onEditRequest}>
        <div className={"contain"}>
          <img src={"/assets/icons/pencil-outline-green.svg"} alt={"icon"} />
          <Typography.Text>{t("申請")}</Typography.Text>
        </div>
      </div>
    )
  }

  if (
    contentData.user_daily_record_request?.status === WAITING_STATUS &&
    contentData.user_daily_record_request?.id > 0
  ) {
    return (
      <div className={"status_pill pending-status-pills"}>
        {t("Waiting Approval")}
      </div>
    )
  }

  if (
    contentData.user_daily_record_request?.status === REJECTED_STATUS &&
    contentData.user_daily_record_request?.id > 0
  ) {
    return (
      <div className={"status_pill absent-status-pills"}>{t("Rejected")}</div>
    )
  }
}

export const CalenderHead = ({
  contentData,
}: {
  contentData: Partial<UserServiceContentResultWithAdditionalData>
}) => {
  const [className, setClassName] = React.useState("time__present")
  const todayDate = dayjs()
  const currentDate = dayjs(contentData?.date)

  const isBeforeToday = currentDate.isBefore(todayDate, "day")
  const isAfterToday = currentDate.isAfter(todayDate, "day")

  //@ts-ignore
  const isToday = dayjs(contentData.date).isToday()

  const breakTimeRecord = pick(contentData, [...ALL_BREAK_TIMES_KEY])

  const totalBreakTime = calcTotalBreakTime(breakTimeRecord, contentData.date)

  const unregistered = React.useMemo(
    () =>
      isBeforeToday &&
      (!contentData.attendance_type || contentData.attendance_type == "0") &&
      (!contentData?.end_time || !contentData?.start_time),
    [contentData, isBeforeToday],
  )

  const isAbsent = React.useMemo(
    () =>
      contentData.attendance_type === USER_ATTENDANCE_VALUES.ABSENCE ||
      contentData.attendance_type ===
        USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION,
    [isBeforeToday, contentData],
  )

  const HeadContent = React.useCallback(() => {
    // lets first check if user has requested any update.
    if (contentData?.user_daily_record_request?.id > 0) {
      // Then check, if user has applied for an attendance edit request.
      if (
        contentData?.user_daily_record_request?.request_type ===
        ATTENDANCE_UPDATE_REQUEST
      ) {
        const newClassName = isAfterToday
          ? "future_date_card_header"
          : contentData?.user_daily_record_request?.status === REJECTED_STATUS
            ? "time__attendance-edit-req-rejected"
            : contentData?.user_daily_record_request?.status === WAITING_STATUS
              ? "time__attendance-edit-req-waiting"
              : "time__attendance-edit-req-accepted"
        setClassName(newClassName)
      }

      // Then check, if user has applied for paid leave.
      if (
        contentData?.user_daily_record_request?.request_type ===
        PAID_LEAVE_REQUEST
      ) {
        const newClassName = isAfterToday
          ? "future_date_card_header"
          : contentData?.user_daily_record_request?.status === REJECTED_STATUS
            ? "time__paid-leave-rejected"
            : contentData?.user_daily_record_request?.status === WAITING_STATUS
              ? "time__paid-leave-waiting"
              : contentData?.user_daily_record_request?.status ===
                    APPROVED_STATUS && isAfterToday
                ? "time__paid-leave-for-future"
                : "time__paid-leave-accepted"
        setClassName(newClassName)

        // and request_type === PAID_LEAVE_REQUEST representing it was a paid leave request
        return <Box ta={"center"}>{t("PAID LEAVE")}</Box>
      }
    }

    if (isAbsent) {
      const newClassName = "time__absent"
      setClassName(newClassName)
      return <Box ta={"center"}>{t("Absent")}</Box>
    }

    if (unregistered) {
      const newClassName = "time__unregistered"
      setClassName(newClassName)
      return <Box ta={"center"}>{t("Unregistered")}</Box>
    }

    return (
      <>
        <Flex align={"center"} gap={4}>
          {isAfterToday ? (
            <>
              <span className={"time__content__label"}>{t("Schedule")}</span>
              {isAfterToday &&
              !contentData?.attendance_shift?.attendance_start_time
                ? "00:00"
                : contentData?.attendance_shift?.attendance_start_time}{" "}
              {" ~ "}
              {isAfterToday &&
              !contentData?.attendance_shift?.attendance_end_time
                ? "00:00"
                : contentData?.attendance_shift?.attendance_end_time}
            </>
          ) : (
            <>
              <span className={"time__content__label"}>
                {isAfterToday ? t("Schedule") : t("出勤: ")}
              </span>
              {(isToday || isAfterToday) && !contentData?.start_time
                ? "00:00"
                : contentData?.start_time}{" "}
              {" ~ "}
              {(isToday || isAfterToday) && !contentData?.end_time
                ? "00:00"
                : contentData?.end_time}
            </>
          )}
        </Flex>

        <Flex align={"center"} gap={4}>
          <span className={"time__content__label"}>
            {isAfterToday ? t("Break time") : t("休憩: ")}
          </span>
          {isAfterToday
            ? breakTimeInString(
                +contentData?.attendance_shift?.attendance_rest_mins * 60 || 0,
              )
            : totalBreakTime}
        </Flex>
      </>
    )
  }, [contentData, unregistered, totalBreakTime, isAfterToday, isBeforeToday])

  return (
    <div
      className={`time__content ${className} ${
        isAfterToday ? "future_date_card_header" : ""
      }`}
    >
      <HeadContent />
    </div>
  )
}

export const TableActionBtns = ({
  status,
  handleEditRequest,
  handleRequestAgain,
  handleCancelationConfirmation,
}: {
  status: number
  handleEditRequest: () => void
  handleCancelationConfirmation: () => void
  handleRequestAgain: () => void
}) => {
  return (
    <Box
      display={"flex"}
      align={"center"}
      justify={"center"}
      direction={"column"}
      gap={8}
    >
      {status === 0 && (
        <ActionButton
          $variant={"action"}
          type={"text"}
          onClick={handleEditRequest}
        >
          <div className={"text"}>
            <img src={"/assets/icons/pencil-outline-green.svg"} alt={"icon"} />
            <Typography.Text>{t("Edit Request")}</Typography.Text>
          </div>
        </ActionButton>
      )}
      {/* cancel only if pending */}
      {status === 0 && (
        <Popconfirm
          title={t("Cancel Request")}
          onConfirm={handleCancelationConfirmation}
        >
          <ActionButton $variant={"error"} type={"text"}>
            <div className={"text"}>
              <img src={"/assets/icons/cross.svg"} alt={"icon"} />
              <Typography.Text>{t("Cancel Request")}</Typography.Text>
            </div>
          </ActionButton>
        </Popconfirm>
      )}

      {status === 2 && (
        <ActionButton
          $variant={"action"}
          type={"text"}
          onClick={handleRequestAgain}
        >
          <div className={"text"}>
            <img src={"/assets/icons/shuffle.svg"} alt={"icon"} />
            <Typography.Text>{t("Request again")}</Typography.Text>
          </div>
        </ActionButton>
      )}
    </Box>
  )
}
