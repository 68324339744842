// packages
import styled from "styled-components"

// commons
import { theme } from "@project/common"

export const StyledConsumerLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  .logo-block {
    display: grid;
    place-items: center;
    gap: 16px;
    font-size: 18px;
    color: ${theme.colors.text};
    font-weight: 700;
  }

  .ant-spin-nested-loading {
    width: 100%;
    .ant-spin-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .login-form {
    border: 1px solid ${theme.colors.border};
    border-radius: 16px;
    padding: 32px;
    background-color: ${theme.colors.white};
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    max-width: 432px;
  }
`

export const InputFieldWrapper = styled.div`
  min-width: 100%;
  .password-info-text {
    font-size: 10px;
    margin-top: 6px;
  }
  .password-wrapper {
    position: relative;
  }
  .visible-icon {
    position: absolute;
    top: 40px;
    right: 10px;
    cursor: pointer;
  }
`

export const ActionBtnWrapper = styled.div`
  .forget-password-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    a,
    p {
      font-size: 14px;
    }
    a {
      color: ${theme.colors.cyan1};
      text-decoration: underline;
    }
  }
`
