import styled from "styled-components"
export const TopContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  row-gap: 16px;
  column-gap: 16px;
  flex-wrap: wrap;
  .print-btn {
    @media print {
      display: none;
      @page {
        margin: 24px;
      }
    }
  }
`
