import {
  MonthPagination,
  useResponsiveMediaQuery,
  useUpdateSearchParams,
} from "@project/common"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useFetchAllAttendance } from "../../../../hooks"
import {
  CalenderTabContentTop,
  StyledCalenderTabContent,
} from "./CalenderTabContent.style"
import { CalenderTabContentCalendarView } from "./CalenderTabContentCalendarView"
import { CalenderTabContentStats } from "./CalenderTabContentStats"
import { ResponsiveCalendarTabContent } from "./ResponsiveCalendarTabContent"

export const CalenderTabContent = (): JSX.Element => {
  const { query } = useRouter()
  const { isTabletOrMobile } = useResponsiveMediaQuery()
  const [updateParams] = useUpdateSearchParams()
  const currentDate = query?.date
    ? dayjs(query?.date as string).isValid
      ? dayjs(query?.date as string)
      : dayjs()
    : dayjs()

  //fetch all attendance in current year and month
  const { allAttendanceRecord, isLoading, isFetching } = useFetchAllAttendance({
    year: dayjs(currentDate).year(),
    month: dayjs(currentDate).month() + 1,
    panel: query?.daily_record as string,
  })

  if (isTabletOrMobile) {
    return (
      <ResponsiveCalendarTabContent
        attendanceRecord={allAttendanceRecord || { data: [] }}
        isLoading={isLoading || isFetching}
        currentDate={currentDate}
      />
    )
  }

  return (
    <StyledCalenderTabContent>
      <CalenderTabContentTop>
        <CalenderTabContentStats
          currentMonth={dayjs(currentDate).month() + 1}
          currentYear={dayjs(currentDate).year()}
        />
        <MonthPagination
          currentMonth={dayjs(currentDate).month()}
          currentYear={dayjs(currentDate).year()}
          showDate={false}
          change={"month"}
          onMonthChange={(_, __, date) => {
            updateParams({ ...query, date: date.format("YYYY-MM") }, "/")
          }}
        />
      </CalenderTabContentTop>
      <CalenderTabContentCalendarView
        attendanceRecord={allAttendanceRecord}
        isLoading={isLoading || isFetching}
        yearMonth={currentDate}
      />
    </StyledCalenderTabContent>
  )
}
