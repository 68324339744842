import {
  ADDITIONAL_ITEM_VALUES,
  AntTable,
  displayLabelForTrialUseSupportInServiceProvisionRecord,
  getUserAttendanceTypesByService,
  SERVICE_TYPE_VALUES,
  Table,
  theme,
  USER_ATTENDANCE_VALUES,
} from "@project/common"
import { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  ADDITIONAL_ITEM_TYPE_VALUES,
  MEDICAL_COLLABORATION_SERVICE_RECORD_SHEET_DISPLAY,
} from "../../../adult/constants"
import {
  SignatureContent,
  StyledTableWrapper,
  TFootExtraContent,
} from "./ServiceProvisionTable.style"

type ServiceSheetDataSource = {
  days: string | number
  day_of_week: string | number
  service_provision_status: string | number
  start_time: string | number
  end_time: string | number
  pickup: string | number
  dropOff: string | number
  visit_special_support_hours: string | number
  meal_addition: string | number
  medical_support: string | number
  commuting_training: string | number
  trial_support: string | number
  regional_collaboration: string | number
  emergency_acceptance: string | number
  intensive_support: string | number
  transition_support: string | number
  outside_support: string | number
  guardian_confirmation: React.JSX.Element
  remarks: React.JSX.Element
}

type ServiceSheetFooterDetail = {
  total_transport_count: number
  visit_special_support_count: number
  // transition_support_count: number
  // outside_facility_support_count: number

  meal_addition_count: number
  medical_support_count: number
  commuting_training_count: number
  trial_support_count: number
  regional_collaboration_count: number
  regional_collaboration_meeting_implementation_count: number
  emergency_acceptance_count: number
  intensive_support_count: number

  count_for_month: number
  cummulative_count: number
}

type ITableData = {
  detail: Array<ServiceSheetDataSource>
  footerDetail: ServiceSheetFooterDetail
}

export const ServiceProvisionTable = ({
  dataSource,
  showFacilityRemarks = false,
  showActualExpenses = false,
  showZeroCostActualExpense = false,
  showAdditionalItems = false,
}: {
  dataSource: any
  showFacilityRemarks?: boolean
  showActualExpenses?: boolean
  showZeroCostActualExpense?: boolean
  showAdditionalItems?: boolean
}): JSX.Element => {
  const { t } = useTranslation()

  const IsTransitionService =
    dataSource?.user_info?.service == SERVICE_TYPE_VALUES.TRANSITION
  const IsEmploymentTypeB =
    !IsTransitionService &&
    dataSource?.user_info?.service == SERVICE_TYPE_VALUES.TYPE_2

  const SPR_COLUMNS: ColumnsType<any> = [
    {
      title: t("Date"),
      dataIndex: "days",
      key: "date",
      align: "center",
    },
    {
      title: t("Week"),
      dataIndex: "day_of_week",
      key: "week",
      align: "center",
      className: "th_week_with_border",
    },
    {
      title: t("Service provision status"),
      dataIndex: "service_provision_status",
      key: "service_provision_status",
      align: "center",
    },
    {
      title: t("Start time"),
      key: "start_date",
      dataIndex: "start_time",
    },
    {
      title: t("End time"),
      key: "end_time",
      align: "center",
      dataIndex: "end_time",
    },
    {
      title: t("Pick & drop addition"),
      align: "center",
      children: [
        {
          title: t("Pick"),
          key: "pick",
          align: "center",
          dataIndex: "pickup",
        },
        {
          title: t("Drop"),
          key: "drop",
          align: "center",
          dataIndex: "dropOff",
        },
      ],
    },
    {
      title: t("Visit special addition"),
      align: "center",
      children: [
        {
          title: t("Hours"),
          dataIndex: "visit_special_support_hours",
          key: "visit_special_support_hours",
          align: "center",
        },
      ],
    },
    {
      title: t("Meal addition"),
      key: "meal_provision",
      dataIndex: "meal_addition",
      align: "center",
    },
    {
      title: t("Medical support addition"),
      dataIndex: "medical_support",
      key: "medical_support",
      align: "center",
    },
    {
      title: t("Commuting training addition"),
      key: "commuting_training",
      align: "center",
      className: !IsTransitionService ? "not_to_be_shown" : "",
      dataIndex: "commuting_training",
      onCell: () => {
        // show only for transition
        return {
          colSpan: IsTransitionService ? 1 : 0,
        }
      },
    },
    {
      title: t("Trial support addition"),
      key: "trial_support",
      dataIndex: "trial_support",
      align: "center",
    },
    {
      title: t("Region cooperate addition"),
      key: "regional_collaboration",
      dataIndex: "regional_collaboration",
      align: "center",
      className: !IsEmploymentTypeB ? "not_to_be_shown" : "",
      onCell: () => {
        // show only for employment B
        return {
          colSpan: IsEmploymentTypeB ? 1 : 0,
        }
      },
    },
    {
      title: t("Regional collaboration meeting implementation addition"),
      key: "regional_collaboration_meeting_implementation",
      dataIndex: "regional_collaboration_meeting_implementation",
      align: "center",
      className: !IsTransitionService ? "not_to_be_shown" : "",
      onCell: () => {
        // show only for transition
        return {
          colSpan: IsTransitionService ? 1 : 0,
        }
      },
    },
    {
      title: t("Emergency acceptance addition"),
      key: "emergency_acceptance",
      dataIndex: "emergency_acceptance",
      align: "center",
    },
    {
      title: t("Intensive support addition"),
      key: "intensive_support",
      dataIndex: "intensive_support",
      align: "center",
    },
    {
      title: t("Transition preparation support system addition"),
      key: "transition_support",
      dataIndex: "transition_support",
      align: "center",
      className: !IsTransitionService
        ? "not_to_be_shown"
        : "th_week_with_border",
      onCell: () => {
        // show only for transition
        return {
          colSpan: IsTransitionService ? 1 : 0,
        }
      },
    },
    {
      title: t("Outside facility support"),
      key: "outside_support",
      dataIndex: "outside_support",
      align: "center",
      className: IsTransitionService
        ? "not_to_be_shown"
        : "th_week_with_border",
      onCell: () => {
        // show only for non transition
        return {
          colSpan: !IsTransitionService ? 1 : 0,
        }
      },
    },
    {
      title: t("Confirmation columns for guardians, etc"),
      dataIndex: "guardian_confirmation",
      key: "guardian_confirmation",
      align: "center",
      className: "th_week_with_border",
    },
    {
      title: t("Remark"),
      key: "remarks",
      dataIndex: "remarks",
      align: "center",
    },
  ]

  const TableData: ITableData = useMemo(() => {
    let transportationUseCount = 0
    let visitSpecialSupportCount = 0
    let intensiveSupportCount = 0
    let transitionSupportCount = 0
    let outsideSupportCount = 0
    let mealAdditionCount = 0
    let medicalSupportCount = 0
    let commutingTrainingCount = 0
    let trialSupportCount = 0
    let regionalCollaborationCount = 0
    let regionalCollaborationMeetingImplementationCount = 0
    let emergencyAcceptanceCount = 0
    const tableData = dataSource?.service_provision_results?.map((data) => {
      const attendance = data?.attendance
      const transport = data?.transport

      const date = attendance?.date?.split("T")[0]

      // day of month
      const days = dayjs(date).date()
      // week day
      const day_of_week = t(dayjs(date).locale("en").format("ddd"))
      // attendance label
      const service_provision_status = t(
        getUserAttendanceTypesByService(attendance?.service_type)?.find(
          (att) => att.value == attendance?.attendance_type,
        )?.label || "",
      )
      // attendance start time
      const start_time = attendance?.start_time
      // attendance end time
      const end_time = attendance?.end_time
      // transportation pickup
      const usedPickUp =
        transport?.pickup_users?.[0]?.use_transportation_flag &&
        !transport?.pickup_users?.[0]?.is_absent
      const pickup = usedPickUp ? "1" : ""
      if (usedPickUp) {
        transportationUseCount++
      }
      // transportation drop
      const usedDropOff =
        transport?.drop_users?.[0]?.use_transportation_flag &&
        !transport?.pickup_users?.[0]?.is_absent
      const dropOff = usedDropOff ? "1" : ""
      if (usedDropOff) {
        transportationUseCount++
      }

      // visit special support addition
      const usedVisitSupport = Array.isArray(attendance?.additional_items)
        ? attendance?.additional_items?.find(
            (item) =>
              item?.additional_item ==
              ADDITIONAL_ITEM_VALUES.VISIT_SPECIAL_SUPPORT,
          )
        : null
      let visit_special_support_hours: string | number = ""

      if (usedVisitSupport) {
        if (usedVisitSupport?.start_time && usedVisitSupport?.end_time) {
          visit_special_support_hours = dayjs(
            `2024-01-01T${usedVisitSupport?.end_time}`,
          ).diff(`2024-01-01T${usedVisitSupport?.start_time}`, "hours")
        }
        visitSpecialSupportCount++
      }

      // meal addition
      const meal_addition = attendance?.meal_id ? "1" : ""
      if (meal_addition) mealAdditionCount++

      // medical addition
      const usedMedicalAddition = Array.isArray(attendance?.additional_items)
        ? attendance?.additional_items?.find(
            (item) =>
              item?.additional_item ==
              ADDITIONAL_ITEM_VALUES.MEDICAL_COOPERATION_SYSTEM,
          )
        : null
      const medical_support = usedMedicalAddition
        ? MEDICAL_COLLABORATION_SERVICE_RECORD_SHEET_DISPLAY?.[
            usedMedicalAddition?.additional_item_value
          ]
        : ""
      if (usedMedicalAddition) medicalSupportCount++

      // commuting training
      const usedCommutingTraining = Array.isArray(attendance?.additional_items)
        ? attendance?.additional_items?.find(
            (item) =>
              item?.additional_item ==
              ADDITIONAL_ITEM_VALUES.COMMUTING_TRAINING,
          )
        : null
      const commuting_training = usedCommutingTraining ? "1" : ""
      if (usedCommutingTraining) commutingTrainingCount++

      // trial support
      const usedTrialSupport = Array.isArray(attendance?.additional_items)
        ? attendance?.additional_items?.find(
            (item) =>
              item?.additional_item == ADDITIONAL_ITEM_VALUES.TRIAL_USE_SUPPORT,
          )
        : null
      const trial_support = usedTrialSupport ? "1" : ""
      if (usedTrialSupport) trialSupportCount++

      // regional collaboration
      const usedRegionalCollaboration = Array.isArray(
        attendance?.additional_items,
      )
        ? attendance?.additional_items?.find(
            (item) =>
              item?.additional_item ==
              ADDITIONAL_ITEM_VALUES.REGIONAL_COLLABORATION_ADDITION,
          )
        : null

      if (usedRegionalCollaboration) regionalCollaborationCount++
      const regional_collaboration = usedRegionalCollaboration ? "1" : ""

      // regional collaboration meeting implementation

      const usedRegionalCollaborationMeetingImplementation = Array.isArray(
        attendance?.additional_items,
      )
        ? attendance?.additional_items?.find(
            (item) =>
              item?.additional_item ==
              ADDITIONAL_ITEM_VALUES.REGIONAL_COLLABORATION_MEETING_IMPLEMENTATION,
          )
        : null

      const regional_collaboration_meeting_implementation =
        usedRegionalCollaborationMeetingImplementation?.additional_item_value ==
        ADDITIONAL_ITEM_TYPE_VALUES.REGIONAL_COLLABORATION_MEETING_I
          ? "1"
          : usedRegionalCollaborationMeetingImplementation?.additional_item_value ==
              ADDITIONAL_ITEM_TYPE_VALUES.REGIONAL_COLLABORATION_MEETING_II
            ? "2"
            : ""

      if (usedRegionalCollaborationMeetingImplementation)
        regionalCollaborationMeetingImplementationCount++

      // emergency acceptance
      const usedEmergencyAcceptance = Array.isArray(
        attendance?.additional_items,
      )
        ? attendance?.additional_items?.find(
            (item) =>
              item?.additional_item ==
              ADDITIONAL_ITEM_VALUES.EMERGENCY_ACCEPTANCE,
          )
        : null
      const emergency_acceptance = usedEmergencyAcceptance ? "1" : ""
      if (usedEmergencyAcceptance) emergencyAcceptanceCount++

      // intensive support
      const usedIntensiveSupport = Array.isArray(attendance?.additional_items)
        ? attendance?.additional_items?.find(
            (item) =>
              item?.additional_item == ADDITIONAL_ITEM_VALUES.INTENSIVE_SUPPORT,
          )
        : null
      if (usedIntensiveSupport) {
        intensiveSupportCount++
      }
      const intensive_support = usedIntensiveSupport
        ? displayLabelForTrialUseSupportInServiceProvisionRecord(
            intensiveSupportCount,
          )
        : ""

      // preparting for migration support
      // only for transition service type
      const usedTransitionSupport =
        IsTransitionService && Array.isArray(attendance?.additional_items)
          ? attendance?.additional_items?.find(
              (item) =>
                item?.additional_item ==
                ADDITIONAL_ITEM_VALUES.TRANSITION_PREPARATION_SUPPORT,
            )
          : null
      if (usedTransitionSupport) {
        transitionSupportCount++
      }
      const transition_support = usedTransitionSupport ? "1" : ""

      // outside facility support
      // only if not transition type
      const usedOutsideSupport =
        !IsTransitionService &&
        attendance?.attendance_type == USER_ATTENDANCE_VALUES.OFFSITE_SUPPORT
      if (usedOutsideSupport) {
        outsideSupportCount++
      }

      const outside_support = usedOutsideSupport ? "1" : ""

      // guardian confirmation
      const guardian_confirmation = (
        <SignatureContent>
          {attendance?.digital_signature_image ? (
            <img
              src={attendance?.digital_signature_image}
              alt={"sign"}
              style={{
                height: "20px",
                width: "auto",
              }}
            />
          ) : (
            <div />
          )}
        </SignatureContent>
      )

      // remarks
      const { remarks, user_attendance_actual_cost, additional_items } =
        attendance
      const remarkContent = showFacilityRemarks ? remarks : ""
      const actualExpenseContent =
        showActualExpenses && Array.isArray(user_attendance_actual_cost)
          ? user_attendance_actual_cost
              ?.filter(
                (ac) => showZeroCostActualExpense || ac.actual_cost_price > 0,
              )
              ?.map((ac) => ac?.actual_cost_name)
              ?.join(", ")
          : ""
      const additionalItemContent =
        showAdditionalItems && Array.isArray(additional_items)
          ? additional_items?.map((ai) => t(ai?.additional_item)).join(", ")
          : ""
      const remarksDisplay = (
        <>
          {remarkContent ? (
            <>
              {remarkContent}
              <br />
            </>
          ) : null}
          {actualExpenseContent ? (
            <>
              {actualExpenseContent}
              <br />
            </>
          ) : null}
          {additionalItemContent ? <>{additionalItemContent}</> : null}
        </>
      )

      return {
        days,
        day_of_week,
        service_provision_status,
        start_time,
        end_time,
        pickup,
        dropOff,
        visit_special_support_hours,
        meal_addition,
        medical_support,
        commuting_training,
        trial_support,
        regional_collaboration,
        regional_collaboration_meeting_implementation,
        emergency_acceptance,
        intensive_support,
        transition_support,
        outside_support,
        guardian_confirmation,
        remarks: remarksDisplay,
      }
    })
    return {
      detail: tableData,
      footerDetail: {
        total_transport_count: transportationUseCount,
        visit_special_support_count: visitSpecialSupportCount,
        meal_addition_count: mealAdditionCount,
        medical_support_count: medicalSupportCount,
        commuting_training_count: commutingTrainingCount,
        trial_support_count: trialSupportCount,
        regional_collaboration_count: regionalCollaborationCount,
        regional_collaboration_meeting_implementation_count:
          regionalCollaborationMeetingImplementationCount,
        emergency_acceptance_count: emergencyAcceptanceCount,
        intensive_support_count: intensiveSupportCount,
        count_for_month: IsTransitionService
          ? transitionSupportCount
          : outsideSupportCount,
        cummulative_count: IsTransitionService
          ? dataSource?.user_info?.cummulative_total_migration_support
          : dataSource?.user_info?.cummulative_total_offsite_support || 0,
      },
    }
  }, [dataSource])

  return (
    <>
      <StyledTableWrapper>
        <Table
          columns={SPR_COLUMNS}
          headerBg={"transparent"}
          dataSource={TableData?.detail || []}
          borderColor={theme.colors.text}
          headerCellPaddingBlock={"4px"}
          headerBorderRadius={0}
          summary={() => {
            const summary = TableData?.footerDetail
            return (
              <AntTable.Summary>
                <AntTable.Summary.Row>
                  <AntTable.Summary.Cell colSpan={5} index={1} align={"center"}>
                    <div className={"t_cell_content"}>{t("Total")}</div>
                  </AntTable.Summary.Cell>
                  <AntTable.Summary.Cell align={"center"} colSpan={2} index={2}>
                    {`${summary.total_transport_count}回`}
                  </AntTable.Summary.Cell>
                  {/* visit special addition */}
                  <AntTable.Summary.Cell align={"center"} index={3}>
                    <div className={"t_cell_content"}>
                      {" "}
                      {`${summary.visit_special_support_count}回`}
                    </div>
                  </AntTable.Summary.Cell>
                  {/* meal addition */}
                  <AntTable.Summary.Cell align={"center"} index={4}>
                    <div className={"t_cell_content"}>
                      {" "}
                      {`${summary.meal_addition_count}回`}
                    </div>
                  </AntTable.Summary.Cell>
                  {/* medical addition */}
                  <AntTable.Summary.Cell align={"center"} index={5}>
                    <div className={"t_cell_content"}>
                      {" "}
                      {`${summary.medical_support_count}回`}
                    </div>
                  </AntTable.Summary.Cell>
                  {/* only for transition service */}
                  {/* commuting training */}
                  {IsTransitionService && (
                    <AntTable.Summary.Cell align={"center"} index={6}>
                      <div className={"t_cell_content"}>
                        {" "}
                        {`${summary.commuting_training_count}回`}
                      </div>
                    </AntTable.Summary.Cell>
                  )}
                  {/* trial support */}
                  <AntTable.Summary.Cell align={"center"} index={7}>
                    <div className={"t_cell_content"}>
                      {" "}
                      {`${summary.trial_support_count}回`}
                    </div>
                  </AntTable.Summary.Cell>
                  {/* only for employment B*/}
                  {/* regional collaboration addtion */}
                  {IsEmploymentTypeB && (
                    <AntTable.Summary.Cell align={"center"} index={8}>
                      <div className={"t_cell_content"}>
                        {" "}
                        {`${summary.regional_collaboration_count}回`}
                      </div>
                    </AntTable.Summary.Cell>
                  )}
                  {/* only for transition */}
                  {/* regional collaboration meeting implementation addition */}
                  {IsTransitionService && (
                    <AntTable.Summary.Cell align={"center"} index={8}>
                      <div className={"t_cell_content"}>
                        {" "}
                        {`${summary.regional_collaboration_meeting_implementation_count}回`}
                      </div>
                    </AntTable.Summary.Cell>
                  )}
                  {/* emergency acceptance addition */}
                  <AntTable.Summary.Cell align={"center"} index={9}>
                    <div className={"t_cell_content"}>
                      {" "}
                      {`${summary.emergency_acceptance_count}回`}
                    </div>
                  </AntTable.Summary.Cell>
                  {/* intensive support addition */}
                  <AntTable.Summary.Cell align={"center"} index={10}>
                    <div className={"t_cell_content"}>
                      {" "}
                      {`${summary.intensive_support_count}回`}
                    </div>
                  </AntTable.Summary.Cell>
                  <AntTable.Summary.Cell
                    colSpan={3}
                    align={"center"}
                    index={12}
                  >
                    <TFootExtraContent>
                      <div className={"cell__key"}>
                        {IsTransitionService
                          ? t("Preparing for migration Support system addition")
                          : t("Off-site support")}
                      </div>
                      <div className={"cell__values_content"}>
                        <div className={"cell__values"}>
                          <div className={"cell_value"}>{t("当月")}</div>
                          <div
                            className={"cell_value"}
                          >{`${summary.count_for_month}日`}</div>
                        </div>
                        <div className={"cell__values"}>
                          <div className={"cell_value"}>{t("累計")}</div>
                          <div
                            className={"cell_value"}
                          >{`${summary.cummulative_count}日/１８０日`}</div>
                        </div>
                      </div>
                    </TFootExtraContent>
                  </AntTable.Summary.Cell>
                </AntTable.Summary.Row>
              </AntTable.Summary>
            )
          }}
          scroll={{ x: 800 }}
        />
      </StyledTableWrapper>
    </>
  )
}
