import {
  Button,
  Card,
  Grid,
  InputField,
  theme,
  useNotification,
} from "@project/common"
import { Flex } from "antd"
import { useFormik } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import { UpdatePasswordSchema } from "../../types"
import { updateUserPassword } from "../../services"
import { useMutation } from "react-query"
import { useRouter } from "next/router"

export const UpdatePasswordContainer = (): JSX.Element => {
  const { t } = useTranslation()
  const { showToast } = useNotification()
  const router = useRouter()
  const { isLoading: isUpdating, mutate: updatePassword } = useMutation(
    updateUserPassword,
    {
      onSuccess: () => {
        showToast({
          type: "success",
          message: `${t("User Password Updated Successfully")}`,
        })
        router.push("/")
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.error
        const errormsg =
          msg === "The password is incorrect"
            ? "The old password you entered doesn't match"
            : msg
        showToast({
          type: "error",
          message: errormsg
            ? t(errormsg)
            : t("Something went wrong. Please contact administrator"),
        })
      },
    },
  )

  const { handleSubmit, errors, setFieldValue, values, touched } = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    onSubmit: (formValues) => {
      updatePassword(formValues)
    },
    validationSchema: UpdatePasswordSchema,
  })
  return (
    <Card title={t("Update password")}>
      <Flex component={"form"} onSubmit={handleSubmit} vertical gap={16}>
        <div>
          <Grid
            background
            labelContent={t("Old Password")}
            labelSpan={5}
            padding={"10px"}
          >
            <InputField
              height={"40px"}
              width={"455px"}
              name={"old_password"}
              value={values.old_password || null}
              onChange={(e) => setFieldValue("old_password", e.target.value)}
              type={"password"}
              error={
                touched?.old_password && errors.old_password
                  ? errors?.old_password
                  : null
              }
            />
          </Grid>
          <Grid
            background
            labelContent={t("New Password")}
            labelSpan={5}
            padding={"10px"}
          >
            <InputField
              height={"40px"}
              width={"455px"}
              subInfo={t("※6 to 16 alphanumeric characters")}
              msgType={"subInfo"}
              name={"new_password"}
              value={values.new_password || null}
              type={"password"}
              onChange={(e) => setFieldValue("new_password", e.target.value)}
              error={
                touched?.new_password && errors.new_password
                  ? errors?.new_password
                  : null
              }
            />
          </Grid>
          <Grid
            background
            labelContent={t("New password (Confirm)")}
            labelSpan={5}
            padding={"10px"}
          >
            <InputField
              height={"40px"}
              width={"455px"}
              msgType={"subInfo"}
              subInfo={t("※6 to 16 alphanumeric characters")}
              name={"confirm_password"}
              type={"password"}
              value={values.confirm_password || null}
              onChange={(e) =>
                setFieldValue("confirm_password", e.target.value)
              }
              error={
                touched?.confirm_password && errors.confirm_password
                  ? errors?.confirm_password
                  : null
              }
            />
          </Grid>
        </div>
        <Flex gap={16} wrap={"wrap"}>
          <Button
            btnText={t("Cancel")}
            shape={"round"}
            borderColor={theme.colors.cyan1}
            textColor={theme.colors.text}
            disabled={isUpdating}
            onClick={() => router.back()}
          />
          <Button
            btnText={t("Save")}
            shape={"round"}
            btnBg={theme.colors.cyan1}
            textColor={theme.colors.white}
            htmlType={"submit"}
            isLoading={isUpdating}
          />
        </Flex>
      </Flex>
    </Card>
  )
}
