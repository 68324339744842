import React, { useRef, useState } from "react"
import { Flex, Skeleton } from "antd"
import { useMutation } from "react-query"
import {
  PlanContentFooter,
  PlanContentGoals,
  PlanDetailActionBtns,
  PlanDetailInfoContent,
  PlanDetailSummary,
  PlanPrintPage,
  theme,
  useNotification,
  usePdfDownload,
  usePrint,
} from "@project/common"
import { updateIndividualSupportPlanSignature } from "../../services"
import { ContentWrapper } from "../../components/ContentWrapper"
import { useTranslation } from "react-i18next"
import { useAuthContext } from "../../contexts/AuthProvider"
export const PlanContent = ({
  planType,
  title,
  isLoading,
  userSupportData,
  refetchPlan,
}: {
  planType: "draft" | "non-draft"
  title: string
  isLoading?: boolean
  userSupportData: any
  refetchPlan: () => void
}): JSX.Element => {
  const contentRef = useRef<HTMLDivElement>(null)
  const [openSignatureModal, setOpenSignatureModal] = useState(false)
  const { showToast } = useNotification()
  const { t } = useTranslation()
  const { userInformation } = useAuthContext()

  const { isPrinting, handlePrint } = usePrint({
    contentRef: contentRef,
    pageStyle: `
      margin: 0.8cm;
    `,
  })
  const { handlePrintPDF } = usePdfDownload({
    filename: title?.replace(/\s+/g, "_"),
    mainContent: () => (
      <PlanPrintPage
        data={userSupportData?.data?.userSupport}
        ref={contentRef}
        goals={userSupportData?.data?.userSupportItems || []}
        operationData={operationData}
        title={userInformation?.user?.username}
      />
    ),
  })
  const operationData = {
    display_content: ["1", "2", "3", "4", "7", "8"],
    consent_date: ["2"],
    parent_sign: ["1"],
    print_settings: "",
    statementText: {
      consentStatement: t(
        "I have received an explanation of the above individual support plan, and have agreed to its contents.",
      ),
      consentStatementForChild: t(
        "I have explained the above individual support plan.",
      ),
    },
  }

  //update plan signature
  const { mutate: updateSignature, isLoading: isSignatureSaving } = useMutation(
    {
      mutationFn: updateIndividualSupportPlanSignature,
      onSuccess: () => {
        showToast({
          type: "success",
          message: t("Updated Successfully"),
        })
        refetchPlan()
        setOpenSignatureModal(false)
      },
      onError: () => {
        showToast({
          type: "error",
          message: t("Something went wrong. Please contact administrator"),
        })
      },
    },
  )

  return (
    <>
      <ContentWrapper title={title}>
        {isLoading ? (
          <Flex vertical gap={16}>
            <Flex gap={16}>
              <Skeleton.Button active />
              <Skeleton.Button active />
            </Flex>
            <Flex gap={16} vertical>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </Flex>
            <Flex gap={16}>
              <Skeleton.Button active />
              <Skeleton.Button active />
            </Flex>
          </Flex>
        ) : +userSupportData?.data?.userSupport?.id > 0 ? (
          <Flex gap={15} vertical>
            <PlanDetailActionBtns
              hideEditBtn
              hideBackBtn
              onClick={(type: "print" | "pdf") => {
                if (type === "print") {
                  handlePrint()
                }
                if (type === "pdf") {
                  handlePrintPDF()
                }
              }}
              planType={planType}
            />
            <PlanDetailSummary
              defaultData={userSupportData?.data?.userSupport}
            />
            <PlanDetailInfoContent
              details={userSupportData?.data?.userSupport || {}}
            />
            <PlanContentGoals
              defaultData={userSupportData?.data?.userSupportItems || []}
              headerBg={theme.colors.background}
            />
            <PlanContentFooter
              planType={planType}
              planId={userSupportData?.data?.userSupport?.id}
              isSignatureSaving={isSignatureSaving}
              openSignatureModal={openSignatureModal}
              setOpenSignatureModal={setOpenSignatureModal}
              saveSignaturesMutation={(values: any) => {
                updateSignature({
                  values: values,
                  planType: planType,
                })
              }}
              defaultData={userSupportData?.data || {}}
              operationData={operationData}
            />
            <PlanDetailActionBtns
              hideEditBtn
              hideBackBtn
              onClick={(type: "print" | "pdf") => {
                if (type === "print") {
                  handlePrint()
                }
                if (type === "pdf") {
                  handlePrintPDF()
                }
              }}
              planType={planType}
            />
          </Flex>
        ) : (
          <>{t("Not Created")}</>
        )}
      </ContentWrapper>
      {isPrinting && (
        <div style={{ display: "none" }}>
          <PlanPrintPage
            data={userSupportData?.data?.userSupport}
            ref={contentRef}
            goals={userSupportData?.data?.userSupportItems || []}
            operationData={operationData}
            title={userInformation?.user?.username}
          />
        </div>
      )}
    </>
  )
}
