import React from "react"

export const EditIconV2 = () => {
  return (
    <svg
      width={"16"}
      height={"17"}
      viewBox={"0 0 16 17"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
    >
      <g clipPath={"url(#clip0_1319_13193)"}>
        <path
          d={
            "M15.2484 5.04412L11.9542 1.75L9.48366 4.22059L4.54248 9.16177V12.4559H7.8366L12.7778 7.51471L15.2484 5.04412Z"
          }
          stroke={"currentColor"}
          strokeWidth={"1.3"}
          strokeLinecap={"round"}
          strokeLinejoin={"round"}
        />
        <path
          d={"M9.48633 4.2207L12.7804 7.51482"}
          stroke={"currentColor"}
          strokeWidth={"1.3"}
          strokeLinecap={"round"}
          strokeLinejoin={"round"}
        />
        <path
          d={"M6.19508 2.57349H1.25391V15.75H14.4304V10.8088"}
          stroke={"currentColor"}
          strokeWidth={"1.3"}
          strokeLinecap={"round"}
          strokeLinejoin={"round"}
        />
      </g>
      <defs>
        <clipPath id={"clip0_1319_13193"}>
          <rect
            width={"16"}
            height={"16"}
            fill={"white"}
            transform={"translate(0.00390625 0.75)"}
          />
        </clipPath>
      </defs>
    </svg>
  )
}
