import { theme } from "@project/common"
import { Card as AntdCard, CardProps } from "antd"
import React from "react"
import styled from "styled-components"

// styles
const StyledCard = styled(AntdCard)`
  &.ant-card-bordered {
    border: 1px solid ${theme.colors.gray9};
  }

  .ant-card-head {
    border-bottom: none;
    .ant-card-head-title {
      &,
      & > * {
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

  .ant-card-body {
    padding: 20px 30px;
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    .ant-card-body {
      padding: 10px 10px !important;
    }
  }
`

export const ConsumerCard: React.FC<CardProps> = ({
  className,
  title,
  ...props
}) => {
  return (
    <StyledCard
      title={title}
      className={`consumer-card ${className}`}
      {...props}
    />
  )
}
