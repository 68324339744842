import { useResponsiveMediaQuery } from "@project/common"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useFetchAllDailyRecordRequest } from "../../../../hooks"
import { CommonTabContent } from "../../common/CommonTabContent"
import { ResponsiveCommonTabContent } from "../../common/ResponsiveCommonTabContent"

export const LeaveRequestTabContent = () => {
  const { isTabletOrMobile } = useResponsiveMediaQuery()
  const router = useRouter()
  const { date, page } = router.query as any

  const currentDate = date
    ? dayjs(date).isValid
      ? dayjs(date)
      : dayjs()
    : dayjs()

  const { dailyRecordsRequest, isLoading } = useFetchAllDailyRecordRequest({
    page: page || 1,
    pageSize: 20,
    year: dayjs(currentDate).year(),
    month: dayjs(currentDate).month() + 1,
    request_type: 2,
  })
  if (isTabletOrMobile) {
    return (
      <ResponsiveCommonTabContent
        dailyRecordsRequest={dailyRecordsRequest}
        isLoading={isLoading}
        request_type={2}
        currentDate={currentDate}
        record_of={"leave-request"}
      />
    )
  }
  return (
    <CommonTabContent
      request_type={2}
      dailyRecordsRequest={dailyRecordsRequest}
      isLoading={isLoading}
    />
  )
}
