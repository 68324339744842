import {
  AccordionCard,
  Button,
  CheckBox,
  DatePicker,
  LabelTextWithLeftArrow,
  OperationSearchContent,
  RadioGroup,
  SelectInput,
  theme,
} from "@project/common"

import dayjs, { Dayjs } from "dayjs"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAuthContext } from "../../contexts/AuthProvider"
import { YM_FORMAT } from "../../constants"
import { Flex } from "antd"
import { ADDITIONAL_REMARK_CONTENT_WHEN_ACTUAL_EXPENSES_IS_CHECKED } from "@project/common/src/constants"

interface ISearchParams {
  facility_id: string | number
  date: Dayjs
  remarks: boolean
  actualCost: boolean
  additionItem: boolean
  absent: boolean
  actualExpensesDisplaySetting: string | number | null
}
interface IProps {
  searchParams: ISearchParams
  onSearch: (val) => void
}
export const ServiceProvisionOperation: React.FC<IProps> = ({
  searchParams,
  onSearch,
}): JSX.Element => {
  const { t } = useTranslation()
  const [params, setParams] = useState<ISearchParams>(searchParams)
  useEffect(() => {
    setParams(searchParams)
  }, [searchParams])
  const { facilities } = useAuthContext()
  const facilityTypes = facilities?.map((d) => {
    return {
      label: d?.facility_name,
      value: d?.id,
    }
  })

  return (
    <AccordionCard
      customBorder={`1px solid ${theme.colors.border}`}
      bordered={true}
      boxShadow={"none"}
      headerBg={theme.colors.greenish}
      headerBorderRadius={`12px 12px 0px 0px`}
      defaultActiveKey={["user-service-provision"]}
      items={[
        {
          key: "user-service-provision",
          label: t("Operation Options"),
          children: (
            <OperationSearchContent>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow label={t("Facility")} width={"136px"} />
                <SelectInput
                  name={"facilityId"}
                  options={facilityTypes || []}
                  placeholder={"--"}
                  value={params.facility_id}
                  onChange={(val) =>
                    setParams((prev) => ({ ...prev, facility_id: val }))
                  }
                  avoidTranslation={true}
                />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Year month")}
                  width={"136px"}
                />
                <DatePicker
                  name={"date"}
                  date={params.date ? dayjs(params.date) : null}
                  format={YM_FORMAT}
                  picker={"month"}
                  onDateChange={(date: Dayjs | null) => {
                    setParams((prev) => ({ ...prev, date }))
                  }}
                />
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Remark contents")}
                  width={"136px"}
                />
                <div className={"checkbox"}>
                  <CheckBox
                    label={t("Facility registering remarks")}
                    value={params.remarks}
                    checked={params.remarks}
                    onChange={({ target: { checked } }) => {
                      setParams((prev) => ({ ...prev, remarks: checked }))
                    }}
                  />
                  <CheckBox
                    label={t("Actual cost item・Cost")}
                    name={"has_actual_expenses"}
                    value={params.actualCost}
                    checked={params.actualCost}
                    onChange={({ target: { checked } }) => {
                      setParams((prev) => ({ ...prev, actualCost: checked }))
                    }}
                  />
                  {params.actualCost && (
                    <Flex>
                      <RadioGroup
                        options={
                          ADDITIONAL_REMARK_CONTENT_WHEN_ACTUAL_EXPENSES_IS_CHECKED
                        }
                        name={"actual_expenses_display_setting"}
                        value={params.actualExpensesDisplaySetting}
                        onChange={(e) => {
                          setParams((prev) => ({
                            ...prev,
                            actualExpensesDisplaySetting: e.target.value,
                          }))
                        }}
                      />
                    </Flex>
                  )}
                  <CheckBox
                    label={t("Addition items")}
                    name={"additional_items"}
                    value={params.additionItem}
                    checked={params.additionItem}
                    onChange={({ target: { checked } }) => {
                      setParams((prev) => ({ ...prev, additionItem: checked }))
                    }}
                  />
                </div>
              </div>
              <div className={"search__content"}>
                <LabelTextWithLeftArrow
                  label={t("Absent info")}
                  width={"136px"}
                />
                <CheckBox
                  label={t("Display Absence(Not getting addition)")}
                  value={params.absent}
                  checked={params.absent}
                  onChange={({ target: { checked } }) =>
                    setParams((prev) => ({ ...prev, absent: checked }))
                  }
                />
              </div>
              <Button
                btnText={t("Display Change")}
                width={"fit-content"}
                shape={"round"}
                borderColor={theme.colors.cyan4}
                textColor={theme.colors.text}
                onClick={() => onSearch(params)}
              />
            </OperationSearchContent>
          ),
        },
      ]}
    />
  )
}
