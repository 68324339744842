import { Skeleton, Typography } from "antd"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import styled from "styled-components"
import { useAuthContext } from "../../../../contexts/AuthProvider"
import { fetchUserMonthFacilitySchedule } from "../../../../services/userFacilitySchedule.services"
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  .type__content {
    height: 30px;
    width: 70px;
    background-color: #f1f4f5;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .summary_txt {
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 4px;
    color: #000000;
    white-space: nowrap;
    strong {
      color: #e27d00;
      font-weight: 400;
    }
  }
`
export const CalenderTabContentStats = ({
  currentYear,
  currentMonth,
}: {
  currentYear: number
  currentMonth: number
}): JSX.Element => {
  const { t } = useTranslation()
  const { userInformation } = useAuthContext()

  const getServiceType = (type: number | null) => {
    switch (type) {
      case 1:
        return <span className={"type"}>{"A型"}</span>
      case 2:
        return <span className={"type"}>{"B型"}</span>
      case 3:
        return <span className={"type"}>{"移行"}</span>
      default:
        return <span className={"type"}>{"移行"}</span>
    }
  }

  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery({
    queryKey: ["user-application-calendar", currentMonth],
    queryFn: () =>
      fetchUserMonthFacilitySchedule({
        user_id: userInformation?.user.id,
        year: currentYear,
        month: currentMonth,
      }),
    enabled: !!currentMonth,
    refetchOnWindowFocus: false,
    keepPreviousData: false,
  })
  return (
    <Wrapper>
      <Typography.Text strong style={{ fontSize: "20px" }}>
        {dayjs(`${currentYear}-${currentMonth}-01`).isValid()
          ? dayjs(`${currentYear}-${currentMonth}-01`).format("YYYY年MM月")
          : dayjs().format("YYYY年MM月")}
      </Typography.Text>
      <div className={"type__content"}>
        {getServiceType(userInformation?.user?.user_certificate?.service_type)}
      </div>

      <Typography.Text className={"summary_txt"}>
        {t("Current Applied No.")}
        <span> {":"}</span>
        {isLoading || isFetching ? (
          <Skeleton.Button active />
        ) : (
          <>
            <strong>{`${response?.data?.stats?.application_count || 0}/${
              response?.data?.stats?.facility_contract?.benefit_days || "0"
            }`}</strong>
          </>
        )}
      </Typography.Text>
      <Typography.Text className={"summary_txt"}>
        {t("Days of absence")}
        <span> {":"}</span>
        {isLoading || isFetching ? (
          <Skeleton.Button active />
        ) : (
          <strong>{response?.data?.stats?.absent_days}</strong>
        )}
      </Typography.Text>
      <Typography.Text className={"summary_txt"}>
        {t("Remaining Available date")}
        <span> {":"}</span>
        {isLoading || isFetching ? (
          <Skeleton.Button active />
        ) : (
          <strong>{response?.data?.stats?.remaining_days || 0}</strong>
        )}
      </Typography.Text>
    </Wrapper>
  )
}
