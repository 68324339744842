// packages
import cuid from "cuid"
import dayjs from "dayjs"

// commons
import { omit } from "@project/common"

// types
import {
  AttendanceRecUpdateReqPayload,
  UserServiceContentResultWithAdditionalData as SingleAttendanceData,
} from "../../../../../types"
import { AttendanceRequestForm, BreakTime } from "../types"

// constants
import { FULL_DATE_FORMAT_EN } from "../../../../../constants"

/**
 *
 * @param values SingleAttendanceData
 * Data for breakTimes i.e. [new_break_start_time1, new_break_end_time2] comes in a object format
 * but we need these data in array format
 * i.e.
 * ``` 
 * type BreakTime = {
   id: string;
   start_time: string;
  end_time: string; } []
 * ```
 * to work properly with it in front-end hence we are formatting it as required.
 * Later while sending it back as a payload we are required to revert back to its original format.
 */
export const breakTimeRecordToArray = (
  values: Partial<SingleAttendanceData>,
  state?: "NEW" | "OLD",
): BreakTime[] => {
  const key = cuid()

  const startPrefix =
    state === "NEW" ? "new_break_start_time" : "break_start_time"
  const endPrefix = state === "NEW" ? "new_break_end_time" : "break_end_time"

  const breakTimeList = [
    {
      id: key,
      start_time: values[startPrefix],
      end_time: values[endPrefix],
    },
  ]

  for (let i = 2; i <= 5; i++) {
    const uniqueKey = cuid()
    if (values[`${startPrefix}${i}`]) {
      breakTimeList.push({
        id: uniqueKey,
        start_time: values[`${startPrefix}${i}`],
        end_time: values[`${endPrefix}${i}`],
      })
    }
  }
  return breakTimeList
}

/**
 *
 * @param breakTime BreakTime[]
 * @returns Like mentioned above we are required to format these array into object before sending it as payload.
 */
export const revertBreakTimeFormatBack = (breakTime: BreakTime[]) => {
  const totalSetOfBreakTime = breakTime.length

  const startPrefix = "new_break_start_time"
  const endPrefix = "new_break_end_time"

  const breakTimeWithValues = breakTime?.reduce((acc, item, index) => {
    const breakNumber = index + 1

    if (breakNumber === 1) {
      return {
        ...acc,
        [startPrefix]: item.start_time,
        [endPrefix]: item.end_time,
      }
    }

    return {
      ...acc,
      [`${startPrefix}${breakNumber}`]: item.start_time,
      [`${endPrefix}${breakNumber}`]: item.end_time,
    }
  }, {} as any)

  if (totalSetOfBreakTime <= 5) {
    for (let i = totalSetOfBreakTime + 1; i <= 5; i++) {
      breakTimeWithValues[`${startPrefix}${i}`] = null
      breakTimeWithValues[`${endPrefix}${i}`] = null
    }
  }

  return breakTimeWithValues
}

export const sanitizeFormValue = (
  values: AttendanceRequestForm,
): AttendanceRecUpdateReqPayload => {
  const clonedValues = JSON.parse(
    JSON.stringify(values),
  ) as AttendanceRequestForm

  return {
    ...omit(clonedValues, ["break_time_list"]),
    ...revertBreakTimeFormatBack(clonedValues.break_time_list),
    date: dayjs(clonedValues.date).format(FULL_DATE_FORMAT_EN),
  }
}
