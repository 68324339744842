import React from "react"
import Image from "next/image"

// packages
import * as yup from "yup"
import { t } from "i18next"
import { useFormik } from "formik"

// styles
import { ResetFormContainer } from "./ForgotPassword.styles"
import { Flex } from "antd"
import Link from "next/link"
import {
  Box,
  Button,
  InputField,
  theme,
  useNotification,
} from "@project/common"
import { useMutation } from "react-query"
import { resetPassword } from "../../services"
import { useRouter } from "next/router"

// Validation schema
export const validationSchema = yup.object().shape({
  email: yup.string().email(t("Enter a valid Email")).required(t("Required")),
  login_id: yup.string().required(t("Required")),
})

// Inferred Type
type ResetValue = yup.InferType<typeof validationSchema>

export const ForgotPassword = () => {
  const { showToast } = useNotification()
  const userSuffix = process.env.NEXT_PUBLIC_USER_SUFFIX

  const router = useRouter()
  const { mutate, isLoading } = useMutation({
    mutationFn: resetPassword,
    onSuccess: () => {
      router?.push("/password-reset-complete")
    },
    onError: (error?: any) => {
      if (error.code === "auth/user-not-found") {
        showToast({
          type: "error",
          message:
            t("Email address does not match") +
            "\n" +
            t("Please enter your registered email address"),
        })
        return
      }
      showToast({
        type: "error",
        message: t("User does not match"),
      })
    },
  })
  const formik = useFormik<ResetValue>({
    initialValues: {
      email: "",
      login_id: "",
    },
    validationSchema,
    onSubmit: (values) => {
      // Define a regex pattern for a valid email address
      let login_id = values.login_id
      const pattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"
      if (!values.login_id.match(pattern)) {
        login_id = values.login_id + userSuffix
      }

      mutate({
        email: values?.email,
        login_id: login_id,
      })
    },
  })

  return (
    <Box
      display={"flex"}
      direction={"column"}
      justify={"center"}
      align={"center"}
      h={"100vh"}
      w={"100%"}
      px={16}
    >
      <Image
        src={"/assets/logo.svg"}
        alt={t("logo")}
        height={100}
        width={100}
      />

      <Box component={"h3"} mb={42} mt={16} fz={"calc(18px)"} fw={700}>
        {t("Forgot password")}
      </Box>

      <ResetFormContainer
        w={{
          "4xs": "100%",
          xs: "427px",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box mb={20} maw={"calc(368px)"}>
            <InputField
              name={"login_id"}
              height={"48px"}
              width={"368px"}
              label={t("Login ID")}
              bg={"transparent"}
              borderRadius={"8px"}
              className={"text-field"}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder={t("Enter your registered Login ID")}
              error={formik.touched.login_id && formik.errors.login_id}
              info={"※" + t("Enter your registered Login ID")}
            />
          </Box>
          <Box mb={20} maw={"calc(368px)"}>
            <InputField
              name={"email"}
              height={"48px"}
              width={"368px"}
              label={t("Email")}
              bg={"transparent"}
              borderRadius={"8px"}
              className={"text-field"}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder={t("Enter Email")}
              error={formik.touched.email && formik.errors.email}
              info={t(
                "*A Password reset link will be sent to the entered Email Address.",
              )}
            />
          </Box>
          <Button
            width={"100%"}
            size={"large"}
            btnText={t("Submit")}
            htmlType={"submit"}
            btnBg={theme.colors.cyan1}
            textColor={theme.colors.white}
            hoverBtnBg={theme.colors.cyan2}
            hoverTextColor={theme.colors.cyan1}
            isLoading={isLoading}
          />

          <Flex align={"center"} justify={"center"}>
            <Link href={"/login"}>
              <a
                style={{
                  textDecoration: "underline",
                  marginTop: "4px",
                  color: theme.colors.action,
                }}
              >
                {t("Go to Login")}
              </a>
            </Link>
          </Flex>
        </form>
      </ResetFormContainer>
    </Box>
  )
}
