import { useRouter } from "next/router"
import { useState } from "react"

// packages
import { Button, Flex } from "antd"
import { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import { t } from "i18next"

// commons
import {
  MonthPagination,
  Table,
  theme,
  useResponsiveMediaQuery,
  useUpdateSearchParams,
} from "@project/common"

// styles
import { StyledHeader, TableWrapper } from "../../DailyRecord.styles"

// utils
import { generateFullDataSource } from "../../utils/generateFullDataSource"

// types
import { HistoryTableData } from "../../types"

// assets
import { EditIconV2 } from "../../../../public/assets/icons/comp/EditIconV2"

// constants
import { PHYSICAL_CONDITION_OBJ } from "../../constants"

import { useAuthContext } from "../../../../contexts/AuthProvider"
import { useFetchAllDailyRecord } from "../../../../hooks"
import { HistoryTabDailyRecordEditModal } from "../TabContentModals/HistoryTabDailyRecordEditModal"
import { ResponsiveHistoryTabContent } from "./ResponsiveHistoryTabContent"

export const HistoryTabContent = () => {
  const { query } = useRouter()
  const [updateParams] = useUpdateSearchParams()
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})
  const { isTabletOrMobile } = useResponsiveMediaQuery()
  const { workData } = useAuthContext()

  const currentDate = query?.date
    ? dayjs(query?.date as string).isValid
      ? dayjs(query?.date as string)
      : dayjs()
    : dayjs()

  //fetch all daily record
  const { dailyRecords, isLoading, isFetching } = useFetchAllDailyRecord({
    year: dayjs(currentDate).year(),
    month: dayjs(currentDate).month() + 1,
  })

  const COLUMN: ColumnsType<HistoryTableData> = [
    {
      title: t("Date"),
      dataIndex: "date",
      key: "date",
      width: 65,
      align: "center",
      render: (val: HistoryTableData["date"]) => dayjs(val).format("D日"),
    },
    {
      title: t("Week2"),
      dataIndex: "date",
      key: "date",
      align: "center",
      width: 30,
      render: (val: HistoryTableData["date"]) => dayjs(val).format("dd"),
    },
    {
      title: t("Status"),
      dataIndex: "physical_condition",
      key: "physical_condition",
      align: "center",
      width: 50,
      render: (val: HistoryTableData["physical_condition"]) =>
        val ? t(PHYSICAL_CONDITION_OBJ[val]) : "",
    },
    {
      title: t("Work Detail"),
      dataIndex: "work_detail",
      key: "work_detail",
      align: "center",
      width: 410,
      render: (_, data: any) => {
        const am = `${t("AM")} :`
        const pm = `${t("PM")} :`
        const AM = workData
          ?.filter((work) => data?.work_detail_am?.includes(work?.value))
          .map((val) => val?.label)
          .join(", ")
        const PM = workData
          ?.filter((work) => data?.work_detail_pm?.includes(work?.value))
          .map((val) => val?.label)
          .join(", ")
        return (
          <div className={"work_detail"}>
            <div className={"am"}>
              {AM ? am : ""} {AM || ""}
            </div>
            <div className={"am"}>
              {PM ? pm : ""} {PM || ""}
            </div>
          </div>
        )
      },
    },
    {
      title: t("Impressions"),
      dataIndex: "work_impression",
      key: "work_impression",
      align: "center",
      width: 396,
      render: (val) => <div className={"work_impression"}>{val}</div>,
    },
    {
      title: t("Remarks "),
      dataIndex: "remarks",
      key: "remarks",
      align: "center",
      width: 160,
      render: (val) => <div className={"remarks"}>{val}</div>,
    },
    {
      title: t("Edit"),
      key: "edit",
      align: "center",
      width: 35,
      render: (row) => {
        if (!row?.id) {
          return "-"
        }
        return (
          <Flex justify={"center"}>
            <Button
              type={"text"}
              size={"small"}
              style={{
                display: "flex",
                color: theme.colors.text,
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={<EditIconV2 />}
              onClick={() => {
                setIsEditModalOpen(true)
                setSelectedRow(row)
              }}
            />
          </Flex>
        )
      },
    },
  ]
  if (isTabletOrMobile) {
    return (
      <ResponsiveHistoryTabContent
        dailyRecords={dailyRecords?.data || []}
        isLoading={isLoading}
      />
    )
  }
  return (
    <>
      <div>
        <StyledHeader
          display={"flex"}
          align={{ "0px": "flex-start", "825px": "center" }}
          mb={20}
          justify={"space-between"}
          wrap={"wrap"}
          gap={10}
          direction={{
            "0px": "column",
            "825px": "row",
          }}
        >
          <h4 className={"title-header"}>
            {t("{{engDate}} Daily record history2", {
              engDate: currentDate.locale("en").format("MMMM, YYYY"),
              jaDate: currentDate.format("YYYY年MM月"),
            })}
          </h4>
          <MonthPagination
            className={"month-pagination"}
            currentMonth={currentDate.month()}
            currentYear={currentDate.year()}
            showDate={false}
            onMonthChange={(_, __, date) => {
              updateParams({ ...query, date: date.format("YYYY-MM") }, "/")
            }}
          />
        </StyledHeader>
        <TableWrapper>
          <Table
            columns={COLUMN}
            dataSource={generateFullDataSource(
              dailyRecords?.data || [],
              currentDate,
            )}
            loading={isLoading || isFetching}
          />
        </TableWrapper>
      </div>
      {isEditModalOpen && (
        <HistoryTabDailyRecordEditModal
          title={t("Edit")}
          open={isEditModalOpen}
          onCancel={() => {
            setSelectedRow({})
            setIsEditModalOpen(false)
          }}
          footer={null}
          data={selectedRow}
          onSave={() => {
            return null
          }}
        />
      )}
    </>
  )
}
