import { CloseOutlined } from "@ant-design/icons"
import {
  BusinessCalendarPagination,
  Table,
  Tag,
  theme,
  useUpdateSearchParams,
} from "@project/common"
import { Flex, Popconfirm, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  ATTENDANCE_UPDATE_REQUEST,
  PAID_LEAVE_REQUEST,
  SLASH_DATE_FORMAT,
} from "../../../../adult/constants"
import { REQUEST_STATUS } from "../../../constants"
import { useAuthContext } from "../../../contexts/AuthProvider"
import {
  useDailyRecordMutation,
  userCancelDailyRecordRequest,
  userReRequestDailyRecordRequest,
} from "../../../hooks"
import {
  UserDailyRecordRequestWithAttendance as ChangeAttendanceRecReq,
  OneDailyRecordRequest,
} from "../../../types"
import { breakTimeInStringV2, getSumOfAllBreakTimes } from "../../../utils"
import { EditRequestFormModal } from "../TabContents/TabContentModals/EditRequestFormModal"
import { editRequestEditModal } from "../TabContents/TabContentModals/utils/editRequestFormMapper"
import { TableActionBtns } from "./CalenderContentMacroComp"

export const CommonTabContent = ({
  request_type,
  dailyRecordsRequest,
  isLoading,
}: {
  request_type: number
  dailyRecordsRequest: OneDailyRecordRequest
  isLoading: boolean
}): JSX.Element => {
  const { t } = useTranslation()

  const [isOpen, setOpen] = useState(false)
  const [isPopconfirm, setIsPopconfirm] = useState(false)
  const [idOfCancelItem, setIdOfCancelItem] = useState(null)

  const [selectedRow, setSelectedRow] = useState<ChangeAttendanceRecReq>(null)

  const currentRequestType = useRef(null)

  const { userInformation } = useAuthContext()

  const router = useRouter()

  const [updateParams] = useUpdateSearchParams()
  const { date, page } = router.query as any

  const currentDate = date
    ? dayjs(date).isValid
      ? dayjs(date)
      : dayjs()
    : dayjs()

  const { mutate, isUpdating } = useDailyRecordMutation({
    onSuccess: () => {
      setOpen(false)
      setSelectedRow(null)
    },
  })

  const getCurrenRequestType = (): string => currentRequestType.current

  //cancel request
  const { cancelRequest, isUpdating: isCanceling } =
    userCancelDailyRecordRequest({
      onSuccess: () => {
        setIdOfCancelItem(null)
        setIsPopconfirm(false)
      },
      requestType: () => getCurrenRequestType(),
    })

  // rerequest
  const { reRequest, isUpdating: isReRequesting } =
    userReRequestDailyRecordRequest()

  const ER_COLUMNS: ColumnsType<ChangeAttendanceRecReq> = [
    {
      key: "1",
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Requested on")}</span>,
      align: "center",
      width: 140,
      dataIndex: "created_datetime",
      render: (_, rec) =>
        dayjs(rec?.created_datetime).format(SLASH_DATE_FORMAT + " HH:mm:ss"),
    },
    {
      key: "2",
      title: (
        <span style={{ whiteSpace: "nowrap" }}>{t("Registered time")}</span>
      ),
      align: "center",
      width: 140,
      render: (_, row) => {
        const sumOfAllBreaks = getSumOfAllBreakTimes(
          row.attendance[0],
          currentDate.format("YYYY-MM-DD"),
        )
        const totalBreakTime = breakTimeInStringV2(sumOfAllBreaks)
        return (
          <div>
            <span>
              {row?.attendance[0]?.start_time}
              {" ~ "}
              {row?.attendance[0]?.end_time}
            </span>
            <br />
            <span>{`( ${totalBreakTime} )`}</span>
            <br />
            <span>{dayjs(row?.date).format(SLASH_DATE_FORMAT)}</span>
          </div>
        )
      },
    },
    {
      key: "changed_time",
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Changed time")}</span>,
      align: "center",
      width: 140,
      render: (_, row) => {
        const sumOfAllBreaks = getSumOfAllBreakTimes(
          row,
          currentDate.format("YYYY-MM-DD"),
          true,
        )
        const totalBreakTime = breakTimeInStringV2(sumOfAllBreaks)
        return (
          <div>
            <span>
              {row?.new_check_in_time}
              {" ~ "}
              {row?.new_check_out_time}
            </span>
            <br />
            <span>{`( ${totalBreakTime} )`}</span>
          </div>
        )
      },
    },
    {
      key: "3",
      title: t("Reason"),
      align: "center",
      width: 200,
      dataIndex: "reason",
      render: (val) => val || "-",
    },
    {
      key: "4",
      title: t("Status2"),
      align: "center",
      width: 200,
      dataIndex: "status",
      render: (val: ChangeAttendanceRecReq["status"], rec) => {
        const value = REQUEST_STATUS?.find((v) => v?.value === val)?.label
        return (
          <>
            <Tag
              title={t(value)}
              color={val === 1 ? "success" : val === 0 ? "warning" : "error"}
              style={{
                textTransform: "uppercase",
              }}
            />

            {val === 2 && (
              <Typography.Paragraph style={{ marginTop: "8px" }}>
                {rec?.rejected_reason}
              </Typography.Paragraph>
            )}
          </>
        )
      },
    },
    {
      key: "5",
      title: t("Actions"),
      align: "center",
      width: 140,
      render: (_, row) => {
        const handleEditRequest = () => {
          setSelectedRow(row)
          setOpen(true)
        }

        const handleCancelationConfirmation = () => {
          currentRequestType.current = "Attendance Edit Request"
          cancelRequest(row?.id)
        }

        const handleRequestAgain = () => {
          setSelectedRow({ ...row, requestTo: "re-request", status: 0 })
          setOpen(true)
        }

        return (
          <TableActionBtns
            status={row.status}
            handleRequestAgain={handleRequestAgain}
            handleEditRequest={handleEditRequest}
            handleCancelationConfirmation={handleCancelationConfirmation}
          />
        )
      },
    },
  ]

  const LR_COLUMNS: ColumnsType<ChangeAttendanceRecReq> = [
    {
      key: "1",
      title: <span style={{ whiteSpace: "nowrap" }}>{t("Requested on")}</span>,
      align: "center",
      width: 140,
      render: (_, rec) => {
        return dayjs(rec?.created_datetime).format(
          SLASH_DATE_FORMAT + " HH:mm:ss",
        )
      },
    },
    {
      key: "2",
      title: (
        <span style={{ whiteSpace: "nowrap" }}>{t("Requested Date")}</span>
      ),
      align: "center",
      width: 140,
      render: (_, rec) => dayjs(rec?.date).format(SLASH_DATE_FORMAT),
    },
    {
      key: "3",
      title: t("Reason"),
      align: "center",
      width: 500,
      dataIndex: "reason",
    },
    {
      key: "4",
      title: t("Status"),
      align: "center",
      width: 140,
      dataIndex: "status",
      render: (val: ChangeAttendanceRecReq["status"], rec) => {
        const label = REQUEST_STATUS?.find((v) => v?.value === val)?.label
        return (
          <>
            <Tag
              title={t(label)}
              color={val === 1 ? "success" : val === 0 ? "warning" : "error"}
              style={{
                textTransform: "uppercase",
              }}
            />
            {val === 2 && (
              <Typography.Paragraph style={{ marginTop: "8px" }}>
                {rec?.rejected_reason}
              </Typography.Paragraph>
            )}
          </>
        )
      },
    },
    {
      key: "5",
      title: t("Actions"),
      align: "center",
      width: 140,
      render: (row) => {
        if (row?.status === 2) {
          return (
            <Popconfirm
              title={t("Request again") + "?"}
              onConfirm={() => reRequest(row?.id)}
            >
              <Flex
                gap={8}
                align={"center"}
                style={{
                  cursor: "pointer",
                  borderBottom: `1px solid ${theme.colors.action}`,
                }}
              >
                <img src={"/assets/icons/request-again.svg"} alt={"icon"} />
                <Typography.Text
                  style={{ color: theme.colors.action, whiteSpace: "nowrap" }}
                >
                  {t("Request again")}
                </Typography.Text>
              </Flex>
            </Popconfirm>
          )
        }
        return (
          <Flex vertical gap={8} align={"center"}>
            {(row?.status === 0 || !row?.status) && (
              <>
                <Flex
                  gap={8}
                  align={"center"}
                  style={{
                    cursor: "pointer",
                    borderBottom: `1px solid ${theme.colors.action}`,
                  }}
                  onClick={() => {
                    setSelectedRow(row)
                    setOpen(true)
                  }}
                >
                  <img
                    src={"/assets/icons/pencil-outline-green.svg"}
                    alt={"icon"}
                  />
                  <Typography.Text
                    style={{ color: theme.colors.action, whiteSpace: "nowrap" }}
                  >
                    {t("Edit Request")}
                  </Typography.Text>
                </Flex>
                <Popconfirm
                  open={idOfCancelItem === row?.id && isPopconfirm}
                  title={t("Cancel Request")}
                  placement={"bottom"}
                  onConfirm={() => {
                    currentRequestType.current = "Leave Request"
                    cancelRequest(row?.id)
                  }}
                  cancelButtonProps={{
                    disabled: isCanceling,
                  }}
                  okButtonProps={{
                    loading: isCanceling,
                  }}
                  onCancel={() => {
                    setIdOfCancelItem(row?.id)
                    setIsPopconfirm(false)
                  }}
                >
                  <Flex
                    gap={8}
                    align={"center"}
                    style={{
                      cursor: "pointer",
                      borderBottom: `1px solid ${theme.colors.error}`,
                    }}
                    onClick={() => {
                      setIdOfCancelItem(row?.id)
                      setIsPopconfirm(true)
                    }}
                  >
                    <CloseOutlined style={{ color: theme.colors.error }} />
                    <Typography.Text
                      style={{
                        color: theme.colors.error,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {t("Cancel Request")}
                    </Typography.Text>
                  </Flex>
                </Popconfirm>
              </>
            )}
          </Flex>
        )
      },
    },
  ]

  const handleYearChange = (year) => {
    updateParams(
      {
        page,
        date: dayjs([year, currentDate?.month(), 1]).format("YYYY-MM"),
      },
      "/",
    )
  }

  const handleMonthChange = (month) => {
    updateParams(
      {
        page,
        date: dayjs([currentDate?.year(), month - 1, 1]).format("YYYY-MM"),
      },
      "/",
    )
  }

  return (
    <>
      <Flex vertical gap={16}>
        <BusinessCalendarPagination
          currentYear={currentDate.year()}
          currentMonth={currentDate?.month() + 1}
          handleMonthChange={(_, month) => handleMonthChange(month)}
          handleYearChange={(_, year) => handleYearChange(year)}
        />
        <Table
          columns={
            request_type === ATTENDANCE_UPDATE_REQUEST ? ER_COLUMNS : LR_COLUMNS
          }
          dataSource={
            (request_type === ATTENDANCE_UPDATE_REQUEST
              ? dailyRecordsRequest?.data.filter(
                  (item) => item.request_type === ATTENDANCE_UPDATE_REQUEST,
                )
              : dailyRecordsRequest?.data.filter(
                  (item) => item.request_type === PAID_LEAVE_REQUEST,
                )) || []
          }
          showPaginationOf={"both"}
          current={+page || 1}
          total={dailyRecordsRequest?.count}
          pageSize={20}
          scroll={{ x: 800 }}
          onChange={(val) => {
            updateParams(
              {
                page: val,
                date: dayjs(currentDate).format("YYYY-MM"),
              },
              "/",
            )
          }}
          loading={isLoading || isCanceling || isReRequesting}
        />
        <BusinessCalendarPagination
          currentYear={currentDate.year()}
          currentMonth={currentDate?.month() + 1}
          handleMonthChange={(_, month) => handleMonthChange(month)}
          handleYearChange={(_, year) => handleYearChange(year)}
        />
      </Flex>
      {isOpen && (
        <EditRequestFormModal
          requestType={request_type as 1 | 2}
          open={isOpen}
          onCancel={() => {
            setOpen(false)
            setSelectedRow(null)
          }}
          title={t("Request Form")}
          footer={null}
          onSave={(values) => {
            mutate({
              values: {
                ...values,
                user_id: userInformation?.user?.id,
                facility_id:
                  userInformation?.active_contract?.facility?.id ||
                  selectedRow?.facility_id,
                status: selectedRow?.status,
              },
              id: selectedRow?.id + "",
              requestTo: selectedRow?.requestTo || null,
            })
          }}
          data={editRequestEditModal(selectedRow)}
          isLoading={isUpdating || isReRequesting || isCanceling}
          service_type={selectedRow?.attendance?.[0]?.service_type}
        />
      )}
    </>
  )
}
