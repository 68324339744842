import { Modal } from "@project/common"
import dayjs from "dayjs"
import React from "react"
import { AddDailyRecordForm } from "../AddDailyRecordForm"

export const HistoryTabDailyRecordEditModal = ({
  data = {},
  onCancel,
  ...props
}: {
  open: boolean
  onCancel: () => void
  title: string | React.ReactNode
  footer?: null | React.ReactNode
  data?: any
  isLoading?: boolean
  onSave: (data: any) => void
}): JSX.Element => {
  return (
    <Modal
      {...props}
      destroyOnClose
      onCancel={onCancel}
      width={1200}
      bodyPadding={"10px"}
    >
      <AddDailyRecordForm
        onCancel={onCancel}
        scrollTo={onCancel}
        isModal={true}
        defaultData={{
          ...data,
          physical_condition: data?.physical_condition
            ? String(data?.physical_condition)
            : "1",
          date: dayjs(data?.date) || dayjs(),
          note: data?.remarks || "",
        }}
      />
    </Modal>
  )
}
