import dayjs from "dayjs"
import { UserServiceContentResultWithAdditionalData } from "../../../../types"
import {
  CalenderActionButton,
  CalenderHead,
} from "../../common/CalenderContentMacroComp"
import { ContentWrapper } from "./CalenderCellContent.style"

const isToday = require("dayjs/plugin/isToday")

dayjs.extend(isToday)

export const CalenderCellContent = ({
  contentData,
  onEditRequest,
}: {
  contentData: Partial<UserServiceContentResultWithAdditionalData>
  onEditRequest: () => void
}): JSX.Element => {
  return (
    <ContentWrapper>
      <div className={"time__list__container"}>
        <CalenderHead contentData={contentData} />
      </div>

      <CalenderActionButton
        contentData={contentData}
        onEditRequest={onEditRequest}
      />
    </ContentWrapper>
  )
}
