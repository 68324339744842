import React from "react"
import ReactHtmlParser from "react-html-parser"
import { ContentWrapper } from "../../../components"
import {
  Content,
  ImageWrapper,
  PdfWrapper,
} from "../TodayProgramContainer.style"
import { Flex, Skeleton } from "antd"
import { Button } from "@project/common"
import { useTranslation } from "react-i18next"
import { useRouter } from "next/router"

export const TodayProgramDetail = ({
  programDetail,
  isLoading,
}: {
  programDetail: any
  isLoading: boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  return (
    <Flex vertical gap={16}>
      {isLoading ? (
        <>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </>
      ) : (
        <>
          {" "}
          <Button
            btnText={t("<<Back")}
            width={"fit-content"}
            shape={"round"}
            type={"primary"}
            onClick={() => router?.back()}
          />
          <ContentWrapper title={programDetail?.article_title || ""}>
            <Content>
              {ReactHtmlParser(programDetail.article_content_1)}
            </Content>
            {programDetail.article_content_img_1 !== "" &&
              (programDetail?.article_content_img_1.startsWith("data:image") ? (
                <ImageWrapper>
                  <img src={programDetail?.article_content_img_1} />
                </ImageWrapper>
              ) : (
                <PdfWrapper>
                  <img
                    src={"/assets/icons/daily-report.svg"}
                    alt={"PDF"}
                    width={"30px"}
                  />
                  <div>
                    <a
                      target={"_blank"}
                      href={programDetail?.article_content_img_1}
                      rel={"noopener noreferrer"}
                      download={programDetail?.image1_filename}
                    >
                      <span className={"image-title"}>
                        {programDetail?.image1_filename}
                      </span>
                    </a>
                  </div>
                </PdfWrapper>
              ))}
          </ContentWrapper>
          <Button
            btnText={t("<<Back")}
            width={"fit-content"}
            shape={"round"}
            type={"primary"}
            onClick={() => router?.back()}
          />
        </>
      )}
    </Flex>
  )
}
