import styled from "styled-components"

export const StyledCalenderTabContent = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
export const CalenderTabContentTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
`
