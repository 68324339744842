import { DatePicker, useUpdateSearchParams } from "@project/common"
import { Flex, Skeleton } from "antd"
import dayjs, { Dayjs } from "dayjs"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { FULL_DATE_FORMAT, YM_FORMAT } from "../../../../constants"
import { useAuthContext } from "../../../../contexts/AuthProvider"
import {
  useDailyRecordMutation,
  useDailyRecordMutationRequest,
} from "../../../../hooks/useRecordMutation"
import { AttendanceRecordData } from "../../../../services"
import { UserServiceContentResultWithAdditionalData as SingleAttendanceData } from "../../../../types"
import { generateFullCalendarDataSrc } from "../../utils/generateFullDataSource"
import { EditRequestFormModal } from "../TabContentModals/EditRequestFormModal"
import { calendarEditRequestEditModal } from "../TabContentModals/utils/editRequestFormMapper"
import { CalenderCellContent } from "./CalenderCellContent"
import { CalenderTabContentStats } from "./CalenderTabContentStats"
import { StyledResponsiveCalendarTabContent } from "./ResponsiveCalendarTabContent.style"

export const ResponsiveCalendarTabContent = ({
  attendanceRecord,
  isLoading,
  currentDate,
}: {
  attendanceRecord?: AttendanceRecordData
  isLoading: boolean
  currentDate: Dayjs
}): JSX.Element => {
  const { t } = useTranslation()
  const [active, setActive] = useState(currentDate.format(FULL_DATE_FORMAT))
  const [openFormModal, setOpenFormModal] = React.useState<boolean>(false)
  const [selectedOne, setSelectedOne] =
    React.useState<SingleAttendanceData>(null)
  const { userInformation, queryClient } = useAuthContext()
  const todayDate = currentDate
    ? dayjs(currentDate).isValid
      ? dayjs(currentDate)
      : dayjs()
    : dayjs()
  const [dateValue, setDateValue] = useState(todayDate || dayjs())
  const [updateParams] = useUpdateSearchParams()
  const isAfterToday = currentDate.isAfter(todayDate, "day")
  const isBeforeToday = currentDate.isBefore(todayDate, "day")

  const onSuccess = () => {
    setOpenFormModal(false)
    setSelectedOne(null)
    queryClient.invalidateQueries(["all-attendance-record-my-month"])
  }

  const runOnError = () => {
    setOpenFormModal(false)
    setSelectedOne(null)
  }

  const {
    updateRecordData: makeAttendanceChangeRequest,
    isUpdating: makingAttendanceChangeRequest,
  } = useDailyRecordMutationRequest({
    onSuccess,
    runOnError,
  })

  const {
    mutate: updateAttendanceRequest,
    isUpdating: updatingAttendanceRequest,
  } = useDailyRecordMutation({
    onSuccess,
    runOnError,
  })

  const memoizedModal = React.useMemo(() => {
    if (openFormModal)
      return (
        <EditRequestFormModal
          requestType={isBeforeToday ? 1 : isAfterToday ? 2 : 1}
          title={t("Request Form")}
          open={openFormModal}
          onCancel={() => {
            setOpenFormModal(false)
            setSelectedOne(null)
          }}
          footer={null}
          onSave={(values) =>
            selectedOne.user_daily_record_request.id
              ? updateAttendanceRequest({
                  values: {
                    ...values,
                    user_id: userInformation?.user?.id,
                  },
                  id: selectedOne?.user_daily_record_request?.id + "",
                })
              : makeAttendanceChangeRequest({
                  ...values,
                  user_id: userInformation?.user?.id,
                  facility_id: selectedOne?.facility_id,
                })
          }
          isLoading={makingAttendanceChangeRequest || updatingAttendanceRequest}
          data={calendarEditRequestEditModal(selectedOne)}
          service_type={selectedOne?.service_type}
        />
      )
  }, [openFormModal])

  return (
    <>
      <StyledResponsiveCalendarTabContent>
        {isLoading ? (
          <Flex vertical gap={16}>
            <Skeleton.Button active />
            {generateFullCalendarDataSrc(
              attendanceRecord.data,
              currentDate,
            )?.map((v, index) => (
              <Flex gap={8} key={index}>
                <Skeleton.Button active />
                <Skeleton.Button block active />
              </Flex>
            ))}
          </Flex>
        ) : (
          <>
            <div className={"calendar_tbl_top"}>
              <DatePicker
                date={dateValue || dayjs()}
                format={YM_FORMAT}
                picker={"month"}
                onDateChange={(date) => {
                  setDateValue(date)
                  updateParams(
                    { daily_record: "calender", date: date.format("YYYY-MM") },
                    "",
                  )
                }}
                popupClassName={"date-picker-popup-height-responsive"}
              />
              <CalenderTabContentStats
                currentMonth={dayjs().month() + 1}
                currentYear={dayjs().year()}
              />
            </div>
            <div className={"responsive__calendar_container"}>
              {generateFullCalendarDataSrc(
                attendanceRecord.data,
                currentDate,
              )?.map((source) => {
                const isActive =
                  dayjs(source?.date).format(FULL_DATE_FORMAT) === active
                const isAbsent = false
                return (
                  <div
                    key={source.id}
                    className={`responsive__calendar__content ${
                      isActive ? "active__container" : ""
                    }`}
                  >
                    <div className={"responsive__calendar__content--left"}>
                      <div className={"day_of_m"}>
                        {dayjs(source?.date).format("dd")}
                      </div>
                      <div className={"day_of_m"}>
                        {dayjs(source?.date).format("DD")}
                      </div>
                    </div>
                    <div className={"responsive__calendar__content--right"}>
                      {isActive ? (
                        <CalenderCellContent
                          contentData={source}
                          onEditRequest={() => {
                            setOpenFormModal(true)
                            setSelectedOne(
                              (source as SingleAttendanceData) || null,
                            )
                          }}
                        />
                      ) : (
                        <div
                          className={"inactive__container"}
                          onClick={() =>
                            setActive(
                              dayjs(source?.date).format(FULL_DATE_FORMAT),
                            )
                          }
                        >
                          <div className={"time"}>
                            {isAbsent
                              ? t("Absent")
                              : `${source?.start_time || ""} ~ ${
                                  source?.end_time || ""
                                }`}
                          </div>
                          <div className={"dots"}>
                            <div className={"dot green"}></div>
                            <div className={"dot yellow"}></div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          </>
        )}
      </StyledResponsiveCalendarTabContent>
      {memoizedModal}
    </>
  )
}
