import { Dayjs } from "dayjs"
import { t } from "i18next"
import * as Yup from "yup"

type PhysicalCondition = "1" | "2" | "3"

export type AddDailyRecordFormType = {
  date: Dayjs
  physical_condition: PhysicalCondition
  work_detail_am: any[]
  work_detail_pm: any[]
  work_impression: string
  note: string
}

export interface HistoryTableData
  extends Omit<AddDailyRecordFormType, "note" | "date"> {
  remarks: string
  date: string
  key: string | number
  id: number | string
}

export const AddDailyRecordValidation = Yup.object({
  date: Yup.string().required(t("Please select")),
  physical_condition: Yup.string().required(t("Please select")),
  work_detail_am: Yup.array().test(
    "min-1-work",
    t("Please select"),
    function (value) {
      if (value.length > 0) return true
      const pmWork = this.parent.work_detail_pm
      return pmWork?.length > 0
    },
  ),
  work_detail_pm: Yup.array().test(
    "min-1-work",
    t("Please select"),
    function (value) {
      if (value.length > 0) return true
      const amWork = this.parent.work_detail_am
      return amWork?.length > 0
    },
  ),
  work_impression: Yup.string().required(t("Please enter")),
})

export const RequestFormValidation = Yup.object({
  reason: Yup.string().required(t("Please enter")),
})
