import React, { useState } from "react"
import Link from "next/link"
import Image from "next/image"

// packages
import { t } from "i18next"
import { useFormik } from "formik"
import { Button, Spin } from "antd"

// commons | components
import { InputField } from "@project/common"

// styles
import {
  ActionBtnWrapper,
  InputFieldWrapper,
  StyledConsumerLogin,
} from "./consumerLogin.styles"
import { ConsumerLoginProps, LoginAttrib, LoginValidationSchema } from "./types"
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons"

// constants
const initialLoginVal = {
  email: "",
  password: "",
}

export const ConsumerLogin: React.FC<ConsumerLoginProps> = ({
  loading,
  handleSubmit,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const formik = useFormik<LoginAttrib>({
    initialValues: initialLoginVal,
    validationSchema: LoginValidationSchema,
    onSubmit: (values) => {
      handleSubmit(values)
    },
  })
  const { handleChange, handleBlur, values, errors, touched } = formik

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible)
  }
  return (
    <StyledConsumerLogin>
      <Spin spinning={loading} className={"spin-loader"}>
        <div className={"logo-block"}>
          <Image src={"/assets/imgs/logo-md.png"} width={102} height={102} />
          <h3>{t("My page login")}</h3>
        </div>

        <form className={"login-form"} onSubmit={formik.handleSubmit}>
          <InputFieldWrapper>
            <InputField
              id={"email"}
              name={"email"}
              height={"48px"}
              width={"368px"}
              label={t("Enter Email or id")}
              bg={"transparent"}
              onBlur={handleBlur}
              value={values.email}
              borderRadius={"8px"}
              onChange={handleChange}
              className={"text-field"}
              placeholder={t("Enter Email or id")}
              error={touched?.email && errors?.email && errors.email}
            />
          </InputFieldWrapper>

          <InputFieldWrapper>
            <div className={"password-wrapper"}>
              <InputField
                id={"password"}
                height={"48px"}
                width={"368px"}
                name={"password"}
                type={isPasswordVisible ? "text" : "password"}
                bg={"transparent"}
                onBlur={handleBlur}
                borderRadius={"8px"}
                label={t("Password")}
                value={values.password}
                onChange={handleChange}
                className={"text-field"}
                placeholder={t("Enter Password")}
                error={touched?.password && errors?.password && errors.password}
              />
              <span
                className={"visible-icon"}
                onClick={togglePasswordVisibility}
              >
                {isPasswordVisible ? <EyeFilled /> : <EyeInvisibleFilled />}
              </span>
            </div>

            <p className={"password-info-text"}>
              {t("Password must be more than 6 characters")}
            </p>
          </InputFieldWrapper>

          <ActionBtnWrapper>
            <Button block size={"large"} type={"primary"} htmlType={"submit"}>
              {t("Login")}
            </Button>
            <div className={"forget-password-wrapper"}>
              <p>{t("Forgot your password ? ")}</p>
              <Link href={"/forgot-password"}>{t("reset")}</Link>
            </div>
          </ActionBtnWrapper>
        </form>
      </Spin>
    </StyledConsumerLogin>
  )
}
