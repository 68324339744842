// packages
import { Form } from "formik"
import styled from "styled-components"

// common
import { Box, theme } from "@project/common"

export const StyledForm = styled(Form)`
  .note_grid_wrapper {
    .col__content {
      border-bottom: 1px solid ${theme.colors.border} !important;
    }
    .label.row__header.note_grid_wrapper {
      border-bottom: 1px solid ${theme.colors.border} !important;
    }

    @media screen and (max-width: ${theme.breakpoints.lg}) {
      .col__content {
        border-bottom: none !important;
      }
    }
  }
  .ant-select,
  .ant-select-selector {
    min-height: 50px !important;
  }

  @media screen and (max-width: ${theme.breakpoints.lg}) {
    .grid-content {
      margin-bottom: 16px;
      &--value {
        border: 1px solid ${theme.colors.border} !important;
        border-radius: 0px 0px 5px 5px !important;
        padding: 8px;
        margin-top: -5px !important;
        border-top: 0 !important;
      }
    }
  }
`

export const StyledHeader = styled(Box)`
  padding-bottom: 8px;
  border-bottom: 1px solid ${theme.colors.border};
  .title-header {
    font-size: 18px;
    font-weight: 500;
    color: ${theme.colors.text};
  }

  .month-pagination {
    align-self: flex-end;

    button:last-child {
      margin-right: 0px;
    }
    @media screen and (min-width: 825px) {
      align-self: center;
    }
  }
`
export const TableWrapper = styled.div`
  .work_impression,
  .remarks {
    text-align: left;
    max-width: 300px;
    white-space: pre-wrap;
  }
  .work_detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
  }
`
