export const ALL_BREAK_TIMES_KEY = [
  "break_end_time",
  "break_start_time",
  "break_start_time2",
  "break_end_time2",
  "break_start_time3",
  "break_end_time3",
  "break_start_time4",
  "break_end_time4",
  "break_start_time5",
  "break_end_time5",
] as const

export const ALL_NEW_BREAK_TIMES_KEY = [
  "new_break_end_time",
  "new_break_start_time",
  "new_break_start_time2",
  "new_break_end_time2",
  "new_break_start_time3",
  "new_break_end_time3",
  "new_break_start_time4",
  "new_break_end_time4",
  "new_break_start_time5",
  "new_break_end_time5",
] as const
