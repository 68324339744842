// packages
import { Flex } from "antd"
import cuid from "cuid"
import { FormikProps } from "formik"
import { t } from "i18next"

// commons
import { Box, Button, getEndTimeHour } from "@project/common"
import { SelectTime } from "../../../common/SelectTime"

// styles
import { BreakTimeFieldsContainer } from "../styles"

// types
import { AttendanceRequestForm, BreakTime } from "../types"

const BreakTimeFields = ({
  formik,
}: {
  formik: FormikProps<Partial<AttendanceRequestForm>>
}) => {
  const { setFieldValue, values } = formik

  const handleDelete = (id: string) => {
    const newBreakTimeList = values.break_time_list.filter(
      (item) => item.id !== id,
    )
    setFieldValue("break_time_list", newBreakTimeList)
  }

  const handleAddition = () => {
    const newBreakTime: BreakTime = {
      id: cuid(),
      start_time: "",
      end_time: "",
    }
    const newBreakTimeList = [...values.break_time_list, newBreakTime]
    setFieldValue("break_time_list", newBreakTimeList)
  }

  return (
    <BreakTimeFieldsContainer display={"grid"} gap={16} mt={8}>
      <Box display={"flex"} direction={"column"} gap={16}>
        {values?.break_time_list?.map((item, index) => {
          return (
            <Flex align={"center"} gap={"8px 16px"} wrap={"wrap"} key={item.id}>
              <SelectTime
                value={item.start_time}
                onTimeChange={(val) => {
                  setFieldValue(`break_time_list[${index}][start_time]`, val)
                  setFieldValue(`break_time_list[${index}][end_time]`, null)
                }}
                onBlur={() => null}
              />
              {"~"}
              <SelectTime
                disabled={!item.start_time}
                value={item.end_time}
                onTimeChange={(val) =>
                  setFieldValue(`break_time_list[${index}][end_time]`, val)
                }
                onBlur={() => null}
                END_HOURS_OPTIONS={
                  item.start_time ? getEndTimeHour(item.start_time) : []
                }
              />
              {index !== 0 && (
                <Button
                  className={"delete-button"}
                  btnText={t("Delete")}
                  type={"link"}
                  onClick={() => handleDelete(item.id)}
                />
              )}
            </Flex>
          )
        })}
      </Box>
      <Flex align={"center"} className={"footer-action-btn"}>
        <Button
          btnText={t("Add")}
          shape={"round"}
          type={"grayed"}
          className={"add-button"}
          disabled={values.break_time_list?.length === 5}
          onClick={handleAddition}
        />

        {values.break_time_list?.length === 5 && (
          <p className={"alert-text"}>
            {t("*Cannot add more than five break time")}
          </p>
        )}
      </Flex>
    </BreakTimeFieldsContainer>
  )
}

export default BreakTimeFields
