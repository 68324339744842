import { useNotification } from "@project/common"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import { useAuthContext } from "../contexts/AuthProvider"
import {
  cancelDailyRecordRequest,
  createUserDailyRecordRequest,
  reRequestDailyRecordRequest,
  updateUserDailyRecordRequest,
} from "../services"

export const useDailyRecordMutationRequest = ({
  onSuccess,
  runOnError,
}: {
  onSuccess: () => void
  runOnError?: () => void
}) => {
  const { t } = useTranslation()
  const { showToast } = useNotification()
  const { queryClient } = useAuthContext()
  const { mutate: updateRecordData, isLoading: isUpdating } = useMutation({
    mutationFn: createUserDailyRecordRequest,
    onSuccess: () => {
      showToast({
        type: "success",
        message: t("Created Successfully"),
      })
      onSuccess()
      queryClient?.invalidateQueries({
        predicate: (query) => {
          return query.queryKey[0] === "all-daily-record-request"
        },
      })
    },
    onError: (e: any) => {
      showToast({
        type: "error",
        message: t(
          e?.data?.error?.message ||
            "An error has occurred. Please contact administrator.",
        ),
      })
      if (runOnError) {
        runOnError()
      }
    },
  })
  return {
    updateRecordData,
    isUpdating,
  }
}
export const useDailyRecordMutation = ({
  onSuccess,
  runOnError,
}: {
  onSuccess: () => void
  runOnError?: () => void
}) => {
  const { t } = useTranslation()
  const { showToast } = useNotification()
  const { queryClient } = useAuthContext()
  const { mutate, isLoading: isUpdating } = useMutation({
    mutationFn: updateUserDailyRecordRequest,
    onSuccess: () => {
      showToast({
        type: "success",
        message: t("Created Successfully"),
      })
      onSuccess()
      queryClient?.invalidateQueries({
        predicate: (query) => {
          return query.queryKey[0] === "all-daily-record-request"
        },
      })
    },
    onError: () => {
      showToast({
        type: "error",
        message: t("An error has occurred. Please contact administrator."),
      })
      if (runOnError) {
        runOnError()
      }
    },
  })
  return {
    mutate,
    isUpdating,
  }
}

export const userCancelDailyRecordRequest = ({
  onSuccess,
  requestType = () => "Leave Request",
}: {
  onSuccess: () => void
  requestType?: () => string
}) => {
  const { t } = useTranslation()
  const { showToast } = useNotification()
  const { queryClient } = useAuthContext()
  const { mutate: cancelRequest, isLoading: isUpdating } = useMutation({
    mutationFn: cancelDailyRecordRequest,
    onSuccess: () => {
      showToast({
        type: "success",
        message: t("{{requestType}} cancelled successfully", {
          requestType: t(requestType()),
        }),
      })
      onSuccess()
      queryClient?.invalidateQueries({
        predicate: (query) => {
          return query.queryKey[0] === "all-daily-record-request"
        },
      })
    },
    onError: () => {
      showToast({
        type: "error",
        message: t("An error has occurred. Please contact administrator."),
      })
    },
  })
  return {
    cancelRequest,
    isUpdating,
  }
}

export const userReRequestDailyRecordRequest = () => {
  const { t } = useTranslation()
  const { showToast } = useNotification()
  const { queryClient } = useAuthContext()
  const { mutate: reRequest, isLoading: isUpdating } = useMutation({
    mutationFn: reRequestDailyRecordRequest,
    onSuccess: () => {
      showToast({
        type: "success",
        message: t("Requested again successully"),
      })
      queryClient?.invalidateQueries({
        predicate: (query) => {
          return query.queryKey[0] === "all-daily-record-request"
        },
      })
    },
    onError: () => {
      showToast({
        type: "error",
        message: t("An error has occurred. Please contact administrator."),
      })
    },
  })
  return {
    reRequest,
    isUpdating,
  }
}
