// packages
import { Button, Flex, Popconfirm } from "antd"
import { FastField, Formik } from "formik"

// commons | components
import {
  Box,
  DatePicker,
  ErrorMassage,
  Grid,
  InputAreaField,
  RadioGroup,
  SelectInput,
  dynamicLangString,
  useNotification,
} from "@project/common"
import { ConsumerCard } from "../../../components/ConsumerCard"

// styles
import { StyledForm } from "../DailyRecord.styles"

// types
import { AddDailyRecordFormType, AddDailyRecordValidation } from "../types"

// constants
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import { FULL_DATE_FORMAT_EN } from "../../../constants"
import { useAuthContext } from "../../../contexts/AuthProvider"
import {
  createUserDailyRecord,
  deleteUserDailyRecord,
  fetchWorkMasterData,
  updateUserDailyRecord,
} from "../../../services"
import { PHYSICAL_CONDITION } from "../constants"

const AddDailyRecordForm = ({
  scrollTo,
  defaultData,
  isModal,
  onCancel,
}: {
  onCancel: () => void
  scrollTo: () => void
  defaultData?: Partial<AddDailyRecordFormType & { id: number }>
  isModal?: boolean
}) => {
  const { t } = useTranslation()
  const { showToast } = useNotification()
  const { userInformation, queryClient } = useAuthContext()
  const { mutate, isLoading } = useMutation({
    mutationFn: defaultData?.id ? updateUserDailyRecord : createUserDailyRecord,
  })

  //delete user daily record (history tab)
  const { mutate: deleteRecord, isLoading: isDeleting } = useMutation({
    mutationFn: deleteUserDailyRecord,
    onSuccess: () => {
      showToast({
        type: "success",
        message: t("Deleted Successfully"),
      })
      queryClient.invalidateQueries({
        queryKey: ["all-daily-record"],
      })
      scrollTo()
    },
    onError: () => {
      showToast({
        type: "error",
        message: t("Something went wrong. Please contact administrator."),
      })
    },
  })
  const { data: workData } = useQuery({
    queryKey: ["fetch-work-master-info"],
    queryFn: () => fetchWorkMasterData(),
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 60 * 1000 * 2,
    retry: 1,
    select: (res) => {
      return res?.data?.map((work) => ({
        label: work?.work_name,
        value: `${work?.id}`,
      }))
    },
  })
  const initialValues: AddDailyRecordFormType = {
    date: defaultData?.date || dayjs(),
    note: defaultData?.note || "",
    physical_condition: defaultData?.physical_condition || "1",
    work_detail_am: defaultData?.work_detail_am || [],
    work_detail_pm: defaultData?.work_detail_pm || [],
    work_impression: defaultData?.work_impression || "",
  }
  const isContractEnded = (date) => {
    const givenDate = date?.split("T")?.[0]
    if (!givenDate) {
      return false
    }
    const currentDate = dayjs()
    return !currentDate.isBefore(givenDate)
  }

  return (
    <ConsumerCard
      title={
        <Box display={"flex"} align={"center"} gap={15}>
          <img src={"/assets/icons/ticked-paper.svg"} alt={"icon"} />
          <p>{isModal ? t("Edit daily record") : t("Add Daily record")}</p>
        </Box>
      }
    >
      <Formik<AddDailyRecordFormType>
        initialValues={initialValues}
        validationSchema={AddDailyRecordValidation}
        onSubmit={(values, { resetForm }) => {
          isContractEnded(userInformation?.active_contract?.contract_end_date)
            ? showToast({
                type: "error",
                message: t("User doesn't have any active contract"),
              })
            : mutate(
                {
                  id: defaultData?.id,
                  user_id: userInformation?.user?.id,
                  date: dayjs(values.date).format(FULL_DATE_FORMAT_EN),
                  physical_condition: +values.physical_condition,
                  work_detail_am: values?.work_detail_am,
                  work_detail_pm: values?.work_detail_pm,
                  work_impression: values.work_impression,
                  notes: values.note,
                },
                {
                  onSuccess: () => {
                    showToast({
                      type: "success",
                      message: defaultData?.id
                        ? dynamicLangString([
                            t("Daily Record"),
                            t("Updated Successfully"),
                          ])
                        : dynamicLangString([
                            t("Daily Record"),
                            t("Created Successfully"),
                          ]),
                    })
                    scrollTo()
                    resetForm({
                      values: {
                        date: values?.date || dayjs(),
                        note: "",
                        physical_condition: "1",
                        work_detail_am: [],
                        work_detail_pm: [],
                        work_impression: "",
                      },
                    })
                    queryClient?.invalidateQueries({
                      predicate: (query) => {
                        return query.queryKey[0] === "all-daily-record"
                      },
                    })
                  },
                  onError: () => {
                    showToast({
                      type: "error",
                      message: t(
                        "Something went wrong. Please contact administrator",
                      ),
                    })
                  },
                },
              )
        }}
      >
        {({
          values,
          setFieldValue,
          handleChange,
          errors,
          touched,
          setFieldTouched,
          resetForm,
        }) => {
          return (
            <StyledForm className={"daily-record-add-form"}>
              <Grid
                gridClassName={"grid-content"}
                labelContent={t("Date")}
                className={"row__header"}
                background
                labelSpan={6}
                required
                borderRadius={"5px 5px 0px 0px"}
              >
                <div className={"grid-content--value"}>
                  <DatePicker
                    date={values.date}
                    onBlur={() => setFieldTouched("date", true)}
                    onDateChange={(date) => setFieldValue("date", date)}
                    error={touched?.date && errors?.date && errors?.date}
                  />
                </div>
              </Grid>

              <Grid
                gridClassName={"grid-content"}
                labelContent={t("physical condition")}
                className={"row__header"}
                background
                labelSpan={6}
                required
                borderRadius={"5px 5px 0px 0px"}
              >
                <div className={"grid-content--value"}>
                  <RadioGroup
                    value={values.physical_condition}
                    options={PHYSICAL_CONDITION}
                    name={"physical_condition"}
                    onChange={handleChange}
                    variant={"consumer"}
                  />
                </div>
              </Grid>

              <Grid
                gridClassName={"grid-content"}
                labelContent={t("Today’s Work Detail")}
                className={"row__header"}
                background
                labelSpan={6}
                required
                borderRadius={"5px 5px 0px 0px"}
              >
                <Flex gap={"10px 40px"} wrap={"wrap"} align={"flex-start"}>
                  <Box display={"flex"} align={"center"} gap={8}>
                    <SelectInput
                      value={values?.work_detail_am || null}
                      options={workData || []}
                      name={"work_detail_am"}
                      onChange={(val) => setFieldValue("work_detail_am", val)}
                      placeholder={"---"}
                      width={"200px"}
                      shortSelector
                      mode={"multiple"}
                      height={"fit-content"}
                      popupMatchSelectWidth={false}
                      popupClassName={"work-detail-select"}
                      avoidTranslation={true}
                    />

                    <div style={{ whiteSpace: "nowrap" }}>{t("AM")}</div>
                  </Box>
                  <Box display={"flex"} align={"center"} gap={8}>
                    <SelectInput
                      value={values?.work_detail_pm || null}
                      options={workData || []}
                      name={"work_detail_pm"}
                      onChange={(val) => setFieldValue("work_detail_pm", val)}
                      placeholder={"---"}
                      width={"200px"}
                      height={"fit-content"}
                      shortSelector
                      mode={"multiple"}
                      popupMatchSelectWidth={false}
                      popupClassName={"work-detail-select"}
                      avoidTranslation={true}
                    />
                    <div style={{ whiteSpace: "nowrap" }}>{t("PM")}</div>
                  </Box>
                </Flex>
                {(touched?.work_detail_am || touched?.work_detail_pm) && (
                  <ErrorMassage
                    message={
                      errors?.work_detail_am?.toString() ||
                      errors?.work_detail_pm?.toString()
                    }
                  />
                )}
              </Grid>

              <Grid
                gridClassName={"grid-content"}
                labelContent={t("Today’s Work Impression")}
                className={"row__header"}
                background
                labelSpan={6}
                required
                borderRadius={"5px 5px 0px 0px"}
              >
                <div className={"grid-content--value"}>
                  <FastField name={"work_impression"} placeholder={"F"}>
                    {({ field }) => {
                      return (
                        <InputAreaField
                          id={"work_impression"}
                          name={"work_impression"}
                          rows={4}
                          error={
                            touched?.work_impression &&
                            errors?.work_impression &&
                            errors?.work_impression
                          }
                          {...field}
                        />
                      )
                    }}
                  </FastField>
                </div>
              </Grid>

              <Grid
                gridClassName={"grid-content"}
                labelContent={t("Notes ")}
                className={"row__header note_grid_wrapper"}
                background
                labelSpan={6}
                borderRadius={"5px 5px 0px 0px"}
              >
                <div className={"grid-content--value"}>
                  <FastField name={"note"} placeholder={"F"}>
                    {({ field }) => {
                      return (
                        <InputAreaField
                          id={"notes"}
                          name={"note"}
                          rows={4}
                          {...field}
                        />
                      )
                    }}
                  </FastField>
                </div>
              </Grid>
              <Box
                justify={"space-between"}
                display={"flex"}
                align={"center"}
                wrap={"wrap"}
              >
                <Box justify={"flex-start"} display={"flex"} mt={20} gap={16}>
                  <Button
                    shape={"round"}
                    type={"default"}
                    disabled={isLoading || isDeleting}
                    onClick={() => {
                      if (isModal) {
                        onCancel()
                      }
                      resetForm({
                        values: {
                          date: values?.date || dayjs(),
                          note: "",
                          physical_condition: "1",
                          work_detail_am: [],
                          work_detail_pm: [],
                          work_impression: "",
                        },
                      })
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    shape={"round"}
                    type={"primary"}
                    htmlType={"submit"}
                    loading={isLoading}
                    disabled={isDeleting}
                  >
                    {t("Save ")}
                  </Button>
                </Box>
                {isModal && defaultData?.id && (
                  <Popconfirm
                    title={t("Deleting.Is that OK?")}
                    okText={t("Delete")}
                    cancelText={t("Cancel")}
                    onConfirm={() => deleteRecord(defaultData?.id)}
                    okButtonProps={{
                      danger: true,
                    }}
                  >
                    <Button
                      shape={"round"}
                      type={"primary"}
                      htmlType={"button"}
                      danger
                      loading={isDeleting}
                    >
                      {t("Delete")}
                    </Button>
                  </Popconfirm>
                )}
              </Box>
            </StyledForm>
          )
        }}
      </Formik>
    </ConsumerCard>
  )
}

export { AddDailyRecordForm }
