import {
  Button,
  MonthPagination,
  ServiceProvisionSheetFooterContent,
  ServiceProvisionSheetTitle,
  ServiceProvisionSheetTopSummaryTable,
  removeBlankAttributes,
  theme,
  useUpdateSearchParams,
} from "@project/common"
import { ACTUAL_EXPENSES_RADIO_OPTION_VALUES } from "@project/common/src/constants"
import { Flex, Skeleton } from "antd"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { ContentWrapper } from "../../components/ContentWrapper"
import { useAuthContext } from "../../contexts/AuthProvider"
import { getServiceProvisionRecordSheet } from "../../services"
import { TopContentContainer } from "./ServiceProvision.style"
import { ServiceProvisionOperation } from "./ServiceProvisionOperation"
import { ServiceProvisionTable } from "./ServiceProvisionTable"

export const ServiceProvision = (): JSX.Element => {
  const { t } = useTranslation()
  const { facilities } = useAuthContext()
  const [updateParams] = useUpdateSearchParams()
  const router = useRouter()
  const { query } = router
  const [searchParams, setSearchParams] = useState({
    facility_id: facilities?.[0]?.id || null,
    date:
      query?.date && dayjs(query?.date?.toString()).isValid()
        ? dayjs(query?.date?.toString())
        : dayjs(),
    remarks: query?.remarks ? true : false,
    actualCost: query?.actualCost ? true : false,
    additionItem: query?.additionItem ? true : false,
    absent: query?.absent ? true : false,
    actualExpensesDisplaySetting:
      query?.actualExpensesDisplaySetting?.toString() || "1",
  })

  const { data: response, isLoading } = useQuery({
    queryKey: ["user_service_provision_record", searchParams],
    queryFn: () =>
      getServiceProvisionRecordSheet({
        facility_id: searchParams.facility_id,
        year: searchParams.date.year(),
        month: searchParams.date.month() + 1,
        show_actual_expenses: searchParams.actualCost,
        show_no_addition_absence: searchParams.absent ? 1 : null,
        additional_items: searchParams.additionItem ? 1 : null,
      }),
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    retry: 1,
    cacheTime: 0,
    select: (data) => data?.data,
  })

  const onSearch = (values) => {
    const params = {
      facility_id: values?.facility_id,
      date: values?.date,
      remarks: values?.remarks,
      actualCost: values?.actualCost,
      additionItem: values?.additionItem,
      absent: values?.absent,
      actualExpensesDisplaySetting: values?.actualExpensesDisplaySetting,
    }
    setSearchParams(params)
  }

  useEffect(() => {
    const params = {
      ...searchParams,
      date: searchParams.date.format("YYYY-MM-DD"),
    }
    const query = removeBlankAttributes(params)
    updateParams(query, "/service-provision")
  }, [searchParams])

  const getDateInJPFormat = (date) =>
    dayjs(date).isValid() ? dayjs(date).format("YYYY年MM月DD日") : ""

  return (
    <Flex vertical gap={16}>
      <ServiceProvisionOperation
        searchParams={searchParams}
        onSearch={onSearch}
      />
      <ContentWrapper title={t("Service provision  record sheet")}>
        <Flex vertical gap={16}>
          {isLoading ? (
            <Flex vertical gap={16}>
              <Flex justify={"space-between"} gap={8}>
                <Skeleton.Button active />
                <Flex gap={8}>
                  <Skeleton.Button active />
                  <Skeleton.Button active />
                  <Skeleton.Button active />
                </Flex>
              </Flex>
              <Skeleton active />
              <br />
            </Flex>
          ) : (
            <>
              <TopContentContainer>
                <Button
                  btnText={t("Print")}
                  shape={"round"}
                  iconType={"print"}
                  className={"print-btn"}
                  btnBg={theme.colors.cyan6}
                  textColor={theme.colors.white}
                  borderColor={theme.colors.cyan6}
                  onClick={() => {
                    window.print()
                  }}
                />
                <MonthPagination
                  change={"month"}
                  currentMonth={searchParams?.date?.month()}
                  currentYear={searchParams?.date?.year()}
                  showDate={false}
                  width={"80px"}
                  onMonthChange={(_, __, date) => {
                    setSearchParams((prev) => ({
                      ...prev,
                      date,
                    }))
                  }}
                />
              </TopContentContainer>
              <ServiceProvisionSheetTitle
                year={searchParams?.date?.year()}
                month={searchParams?.date?.month() + 1}
              />
              <ServiceProvisionSheetTopSummaryTable
                recipientNumberKey={t("Recipient  certified number")}
                recipientNumber={response?.user_info?.certificate_number}
                userNameKey={t("User name")}
                userNameValue={response?.user_info?.username}
                contractAmountKey={t("Contract days")}
                contractAmount={
                  <strong>{`${response?.user_info?.benefit_days}日／月`}</strong>
                }
                businessOwner={
                  <strong>{response?.user_info?.facility_name}</strong>
                }
                officeName={t("Facility number")}
                officeNumber={response?.user_info?.business_number}
                provider={t("Facility and company")}
                className={"center"}
              />
            </>
          )}
          <ServiceProvisionTable
            dataSource={response}
            showFacilityRemarks={searchParams?.remarks ? true : false}
            showActualExpenses={searchParams?.actualCost ? true : false}
            showZeroCostActualExpense={
              searchParams?.actualCost &&
              searchParams?.actualExpensesDisplaySetting ==
                ACTUAL_EXPENSES_RADIO_OPTION_VALUES.INCLUDE_ACTUAL_COST_OF_0_YEN
            }
            showAdditionalItems={searchParams?.additionItem ? true : false}
          />
          {isLoading ? (
            <Skeleton active />
          ) : (
            <>
              <ServiceProvisionSheetFooterContent
                cells={[
                  {
                    content: t("Initial addition"),
                    className: "key__cell",
                  },
                  {
                    content: t("Start date"),
                    className: "key__cell",
                  },
                  {
                    content: getDateInJPFormat(
                      response?.user_info?.initial_addition_start_date,
                    ),
                    className: "key__cell",
                  },
                  {
                    content: `30${t("日目")}`,
                    className: "key__cell",
                  },
                  {
                    content: getDateInJPFormat(
                      response?.user_info?.initial_addition_end_date,
                    ),
                    className: "key__cell",
                  },
                  {
                    content: t("Calculation of the month"),
                    className: "key__cell",
                  },
                  {
                    content: `${
                      response?.user_info?.calculation_days_for_current_month ||
                      0
                    }日`,
                    className: "key__cell",
                  },
                ]}
              />
              <Flex justify={"flex-end"}>
                <ServiceProvisionSheetFooterContent
                  minWidth={"15px"}
                  cells={[
                    {
                      content: t(""),
                      className: "ws",
                    },
                    {
                      content: t("All page"),
                      className: "ws",
                    },
                    {
                      content: t(""),
                      className: "ws",
                    },
                    {
                      content: t("page No."),
                      className: "ws",
                    },
                  ]}
                />
              </Flex>
            </>
          )}
        </Flex>
      </ContentWrapper>
    </Flex>
  )
}
